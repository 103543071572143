import {identity, sortBy} from "lodash/fp";
import {type Saga} from "redux-saga";
import {select, takeLatest} from "redux-saga/effects";
import {trackVariable} from "@atg-shared/analytics";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {StartlistSelectors} from "@atg-horse/startlists";
import {TABLE_APPLY_OPTIONS} from "../..";
import type {applyOptions} from "../..";
import {TABLE_TOGGLE_FLAG} from "./tableDisplayOptionsActionTypes";
import {getTableSettings} from "./tableDisplayOptionsSelectors";
import type {toggleFlag} from "./tableDisplayOptionsActions";
import {PERSIST_KEY} from "./tableDisplayOptionsPersistConfig";

type Action =
    | {type: "persist/REHYDRATE"; key: string; payload: Record<string, unknown>}
    | typeof applyOptions
    | typeof toggleFlag;

/**
 * After the tableSettings state has rehydrated (from localStorage), or after it's been updated,
 * update the GTM variables to match.
 */
// @ts-expect-error
function* logTableSettingsAnalytics(action: Action): Saga<void> {
    // wait for redux-persist to update our Redux state from localStorage before calling
    // `trackVariable` to update the GTM variable
    // @ts-expect-error
    if (action.type === "persist/REHYDRATE" && action.key !== PERSIST_KEY) return;

    const tableSettings = yield select(getTableSettings);
    const currentStartlistId = yield select(StartlistSelectors.getCurrentStartlistId);
    const currentStartlist = yield select(
        StartlistSelectors.getCurrentStartlist(currentStartlistId),
    );
    let preparedListName = "";

    if (currentStartlist) {
        const formatListName = () => {
            switch (currentStartlist.name) {
                case "enkel lista":
                    return "enkel lista";
                case "populär":
                    return "popular lista";
                case "detaljerad":
                    return "detaljerad lista";
                default:
                    return "new list";
            }
        };

        preparedListName = formatListName();
    }

    const startlistFlags = Object.keys(tableSettings.startlist.selectedFlags).filter(
        (key) => tableSettings.startlist.selectedFlags[key],
    );

    const startlistColumns = tableSettings.startlist.selectedColumns
        .filter((column: any) => column.value)
        .map((column: any) => column.id);

    const globalFlags = Object.keys(tableSettings.global.selectedFlags).filter(
        (key) => tableSettings.global.selectedFlags[key],
    );

    trackVariable({
        // options should be sorted alphabetically when sent to GTM: [HRS1-475]
        userSelectedStartlistOptions: sortBy(identity, [
            `initialPreparedList ${preparedListName}`,
            ...startlistFlags,
            ...startlistColumns,
        ]),
        userSelectedGlobalOptions: sortBy(identity, globalFlags),
    });
}
// @ts-expect-error
export default function* tableDisplayOptionsAnalyticsSaga(): Saga<void> {
    yield takeLatest(
        // list of all actions that update any of the `tableSettings` we're interesting in
        // @ts-expect-error
        ["persist/REHYDRATE", TABLE_TOGGLE_FLAG, TABLE_APPLY_OPTIONS],
        logTableSettingsAnalytics,
    );
}
