import type {PoolParticipation} from "@atg-horse-shared/bet-types";
import {GameTypes} from "@atg-horse-shared/game-types";

export {SharedBetGameType} from "@atg-horse-shared/game-types";

export type SingleRaceSharedBetGameType = "trio" | "komb" | "tvilling";

/**
 * These are the single race shared bet games.
 */
export const SINGLE_RACE_SHARED_BET_GAMES = [
    GameTypes.trio,
    GameTypes.tvilling,
    GameTypes.komb,
];

export interface VXYSelectionLeg {
    id?: string;
    marks: Array<number>;
    reserves: Array<number>;
    bankerPickHorse?: string;
    number?: number | null;
}

export interface VXYSelections {
    poolParticipation?: PoolParticipation;
    legs: Record<string, VXYSelectionLeg>;
}

export interface SingleRaceSelection {
    marks: Array<number>;
    bankerPickHorse?: string;
}

export interface SingleRaceSelections {
    id: string;
}

export interface TrioSelections extends SingleRaceSelections {
    positions: Record<"1" | "2" | "3", SingleRaceSelection>;
}

export interface KombSelections extends SingleRaceSelections {
    positions: Record<"1" | "2", SingleRaceSelection>;
}

export interface TvilingSelections extends SingleRaceSelections {
    marks: Array<number>;
    baseMarks: Array<number>;
    marksBankerPickHorse?: string;
    baseMarksBankerPickHorse?: string;
}

export type Selections =
    | VXYSelections
    | TrioSelections
    | KombSelections
    | TvilingSelections;

export interface CouponTeamHorse {
    id?: number;
    name: string;
    nationality?: string;
}

export interface CouponTeamBet {
    number: number;
    horse: CouponTeamHorse;
}

export interface CouponTeamRace {
    id?: string;
    trackId?: number;
    raceNumber: number;
    mediaId?: string;
}

export interface CouponTeamVXYRace extends CouponTeamRace {
    bets: Array<CouponTeamBet>;
    reserves: Array<{
        number: number;
    }>;
}

export interface CouponTeamTrioRace extends CouponTeamRace {
    firstPlaceBets: Array<CouponTeamBet>;
    secondPlaceBets: Array<CouponTeamBet>;
    thirdPlaceBets: Array<CouponTeamBet>;
}

export interface CouponTeamKombRace extends CouponTeamRace {
    firstPlaceBets: Array<CouponTeamBet>;
    secondPlaceBets: Array<CouponTeamBet>;
}
