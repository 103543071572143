import {includes, without, mapValues, omitBy} from "lodash/fp";
import {persistReducer} from "redux-persist";
import {
    type AppLocalStorageReadyAction,
    APP_LOCAL_STORAGE_READY,
} from "@atg-shared/storage/domain/atgStorageActions";
import * as Start from "@atg-horse-shared/utils/start";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as GameActions from "atg-horse-game/domain/gameActions";
import {TABLE_APPLY_OPTIONS, TABLE_TOGGLE_FLAG_BY_VALUE} from "../..";
import * as TableDisplayActions from "./tableDisplayOptionsActionTypes";

import type {
    TableSettings,
    StartsShowingResults,
    TableDisplayOptionsState,
    RaceSettings,
} from "./tableDisplayOptions";
import type {
    TableDisplayOptionsAction,
    ToggleRaceInfoExpandedAction,
} from "./tableDisplayOptionsActions";
import tableDisplayOptionsPersistConfig from "./tableDisplayOptionsPersistConfig";

/**
 * This is how long the site should "remember" a user's game specific settings. We need to forget
 * them after a while to prevent localStorage usage from growing over time.
 */
const RACE_SETTINGS_EXPIRE_DELAY = 90 * 24 * 60 * 60 * 1000;

export const defaultTableSettings: TableSettings = {
    // global settings set in bottom section of the "ANPASSA" modal
    global: {
        selectedFlags: {
            showCompactHorseResults: false,
            showRaceComments: true,
            showRaceInfo: false,
            showDagensSpelComment: true,
            showTRMediaComment: true,
            expandAllStartlists: false,
        },
    },
    startlist: {
        selectedFlags: {
            showColumnHeaders: true, // mobile app only
        },
        selectedColumns: [
            {id: "betDistribution", value: true},
            {id: "vOdds", value: true},
        ],
        sortOptions: null,
        isRaceInfoExpanded: false,
        allStartlistsExpanded: false,
        expandedStartlist: undefined,
        raceSettings: {},
    },
    results: {
        selectedFlags: {},
        selectedColumns: [],
        sortOptions: null,
        allStartlistsExpanded: false,
        expandedStartlist: undefined,
        raceSettings: {},
    },
    resultsOverview: {
        selectedFlags: {},
        selectedColumns: [],
        sortOptions: null,
    },
    horseResults: {
        sortOptions: null,
    },
    fullscreenHorseResults: {
        sortOptions: null,
    },
};

export const defaultRaceSettings: RaceSettings = {
    expandedStart: undefined,
    pinnedStarts: [],
    storedAt: undefined,
};

type RaceSettingsState = {
    [raceId: string]: RaceSettings;
};

/**
 * Helper which removes very old game settings data, since this state is persisted to localStorage
 * and would otherwise slowly grow forever.
 */
const removeOldRaces = (
    raceSettingsState: RaceSettingsState,
    currentRaceId: string,
): RaceSettingsState =>
    // @ts-expect-error
    omitBy.convert({cap: false})((raceSettings: RaceSettings, raceId) => {
        const now = Date.now();

        // if the user is viewing an old race which has just expired, don't clean it up just yet
        if (raceId === currentRaceId) return false;
        if (!raceSettings.storedAt) return false;

        // startlist and results settings for races older than 90 days is "forgotten"
        const isExpired = now - raceSettings.storedAt > RACE_SETTINGS_EXPIRE_DELAY;
        return isExpired;
    }, raceSettingsState);

type TableSettingsActions =
    | TableDisplayOptionsAction
    | GameActions.ChangeShowResultsAction
    | AppLocalStorageReadyAction
    | ToggleRaceInfoExpandedAction;

export const tableSettingsReducer = (
    oldState: TableSettings = defaultTableSettings,
    action: TableSettingsActions,
    isOnResultsTab: boolean,
) => {
    // get if user is on startlist or results view
    const currentTab = isOnResultsTab ? "results" : "startlist";
    let state;

    // if user do any of the actions that stores data, we need to check for old data and get the saved settings in currentRaceState
    if (
        action.type === TableDisplayActions.TABLE_TOGGLE_EXPANDED_START ||
        action.type === TableDisplayActions.TABLE_TOGGLE_PINNED_START ||
        action.type === TableDisplayActions.TABLE_TOGGLE_ALL_STARTS_EXPANDED
    ) {
        const updatedRaceSettings = removeOldRaces(
            oldState[currentTab].raceSettings,
            action.payload.raceId,
        );
        state = {
            ...oldState,
            [currentTab]: {
                ...oldState[currentTab],
                raceSettings: updatedRaceSettings,
            },
        };
    } else {
        state = oldState;
    }

    switch (action.type) {
        case TableDisplayActions.TABLE_TOGGLE_EXPANDED_START: {
            // make sure we initialize the state correctly for new races
            const currentRaceState =
                state[currentTab].raceSettings[action.payload.raceId] ??
                defaultRaceSettings;
            if (!currentRaceState.storedAt) {
                currentRaceState.storedAt = Date.now();
            }
            if (
                state.global.selectedFlags.expandAllStartlists &&
                state[currentTab].allStartlistsExpanded
            )
                return state;

            return {
                ...state,

                [currentTab]: {
                    ...state[currentTab],
                    raceSettings: {
                        ...mapValues(
                            (raceState) => ({
                                ...raceState,
                                expandedStart: undefined,
                            }),
                            state[currentTab].raceSettings,
                        ),
                        [action.payload.raceId]: {
                            ...currentRaceState,
                            expandedStart:
                                currentRaceState.expandedStart === action.payload.startId
                                    ? undefined
                                    : action.payload.startId,
                        },
                    },
                },
            };
        }

        case TableDisplayActions.TABLE_TOGGLE_PINNED_START: {
            const currentRaceState =
                state[currentTab].raceSettings[action.payload.raceId] ??
                defaultRaceSettings;
            if (!currentRaceState.storedAt) {
                currentRaceState.storedAt = Date.now();
            }
            return {
                ...state,

                [currentTab]: {
                    ...state[currentTab],
                    raceSettings: {
                        ...state[currentTab].raceSettings,
                        [action.payload.raceId]: {
                            ...currentRaceState,
                            pinnedStarts: includes(
                                action.payload.startId,
                                currentRaceState.pinnedStarts,
                            )
                                ? without(
                                      [action.payload.startId],
                                      currentRaceState.pinnedStarts,
                                  )
                                : [
                                      ...currentRaceState.pinnedStarts,
                                      action.payload.startId,
                                  ],
                        },
                    },
                },
            };
        }

        case TableDisplayActions.TABLE_TOGGLE_ALL_STARTS_EXPANDED: {
            const currentRaceState =
                state[currentTab].raceSettings[action.payload.raceId] ??
                defaultRaceSettings;
            if (!currentRaceState.storedAt) {
                currentRaceState.storedAt = Date.now();
            }
            if (state.global.selectedFlags.expandAllStartlists) {
                return {
                    ...state,

                    [currentTab]: {
                        ...state[currentTab],
                        allStartlistsExpanded: !state[currentTab].allStartlistsExpanded,
                        expandedStartlist: undefined,
                        raceSettings: mapValues(
                            (raceState) => ({
                                ...raceState,
                                expandedStart: undefined,
                            }),
                            state[currentTab].raceSettings,
                        ),
                    },
                };
            }
            const startlistOfExpandedStartIsToggled = Start.isStartInRace(
                currentRaceState.expandedStart,
                action.payload.raceId,
            );

            return {
                ...state,
                [currentTab]: {
                    ...state[currentTab],
                    expandedStartlist:
                        state[currentTab].expandedStartlist === action.payload.raceId
                            ? undefined
                            : action.payload.raceId,
                    raceSettings: {
                        ...state[currentTab].raceSettings,
                        [action.payload.raceId]: {
                            ...currentRaceState,
                            // Any expanded start is "forgotten" when you expand/contract the startlist it's
                            // in instead. If another startlist is expanded/contracted it should stay.
                            expandedStart: startlistOfExpandedStartIsToggled
                                ? undefined
                                : currentRaceState.expandedStart,
                        },
                    },
                },
            };
        }

        case TableDisplayActions.TABLE_TOGGLE_RACE_INFO_EXPANDED: {
            return {
                ...state,
                startlist: {
                    ...state.startlist,
                    isRaceInfoExpanded: !state.startlist.isRaceInfoExpanded,
                },
            };
        }

        case GameActions.CHANGE_SHOW_RESULTS:
            if (!action.payload.showResults) return state;
            return {
                ...state,
                results: {
                    ...state.results,
                    sortOptions: null,
                },
            };

        case TableDisplayActions.TABLE_UPDATE_SORTING:
            return {
                ...state,
                [action.payload.target]: {
                    ...state[action.payload.target as keyof TableSettings],
                    sortOptions: {
                        column: action.payload.column,
                        ascending: action.payload.ascending,
                    },
                },
            };

        case TABLE_TOGGLE_FLAG_BY_VALUE: {
            let newState = {
                ...state,
                [action.payload.target]: {
                    ...state[action.payload.target as keyof TableSettings],
                    selectedFlags: {
                        // @ts-expect-error
                        ...state[action.payload.target].selectedFlags,
                        [action.payload.flagId]: action.payload.value,
                    },
                },
            };

            // when enabling "expand all startlists" also immediately expand all
            // startlists, and contract them for the opposite scenario
            if (action.payload.flagId === "expandAllStartlists") {
                newState = {
                    ...newState,
                    results: {
                        ...newState.results,
                        allStartlistsExpanded:
                            newState.global.selectedFlags.expandAllStartlists,
                    },
                    startlist: {
                        ...newState.startlist,
                        allStartlistsExpanded:
                            newState.global.selectedFlags.expandAllStartlists,
                    },
                };
            }

            return newState;
        }

        case TableDisplayActions.TABLE_TOGGLE_FLAG: {
            const currentState =
                // @ts-expect-error
                state[action.payload.target].selectedFlags[action.payload.flagId];

            let newState = {
                ...state,
                [action.payload.target]: {
                    ...state[action.payload.target as keyof TableSettings],
                    selectedFlags: {
                        // @ts-expect-error
                        ...state[action.payload.target].selectedFlags,
                        [action.payload.flagId]: !currentState,
                    },
                },
            };

            // when enabling "expand all startlists" also immediately expand all
            // startlists, and contract them for the opposite scenario
            if (action.payload.flagId === "expandAllStartlists") {
                newState = {
                    ...newState,
                    results: {
                        ...newState.results,
                        allStartlistsExpanded:
                            newState.global.selectedFlags.expandAllStartlists,
                    },
                    startlist: {
                        ...newState.startlist,
                        allStartlistsExpanded:
                            newState.global.selectedFlags.expandAllStartlists,
                    },
                };
            }

            return newState;
        }

        case TABLE_APPLY_OPTIONS:
            return action.payload.isNewAnpassa
                ? {
                      ...state,
                      [action.payload.target]: {
                          ...state[action.payload.target as keyof TableSettings],
                          selectedFlags: {
                              ...action.payload.flags,
                          },
                          selectedColumns: action.payload.columns,
                      },
                  }
                : {
                      ...state,
                      [action.payload.target]: {
                          ...state[action.payload.target as keyof TableSettings],
                          selectedFlags: {
                              // @ts-expect-error
                              ...state[action.payload.target].selectedFlags,
                              ...action.payload.flags,
                          },
                          selectedColumns: action.payload.columns,
                      },
                  };

        case APP_LOCAL_STORAGE_READY:
            return defaultTableSettings;

        default:
            return state;
    }
};

function isViewingResults(
    state = false,
    action: GameActions.ChangeShowResultsAction,
): boolean {
    if (action.type !== GameActions.CHANGE_SHOW_RESULTS) return state;
    return action.payload.showResults;
}

function hasClosedNewOtherGamesBox(
    state = false,
    action: GameActions.GameAction,
): boolean {
    if (action.type !== GameActions.SET_HAS_CLOSED_NEW_OTHER_GAMES_BOX) return state;

    return true;
}

export const initialStartsShowingResultsState: StartsShowingResults = {
    winnerCount: 0,
    startIds: [],
};

export function startsShowingResults(
    state: StartsShowingResults = initialStartsShowingResultsState,
    action: GameActions.GameAction,
): StartsShowingResults {
    switch (action.type) {
        case GameActions.SHOW_RESULTS_FOR_START:
            return {
                ...state,
                startIds: [...state.startIds, action.payload.startId],
            };
        case GameActions.SET_WINNER_COUNT:
            return {
                ...state,
                winnerCount: action.payload.winnerCount,
            };
        case GameActions.CLEAR_STARTS_SHOWING_RESULTS:
            return initialStartsShowingResultsState;
        default:
            return state;
    }
}

const tableDisplayOptionsReducer = (
    state = {},
    action: GameActions.ChangeShowResultsAction,
) => ({
    hasClosedNewOtherGamesBox: hasClosedNewOtherGamesBox(
        (state as TableDisplayOptionsState).hasClosedNewOtherGamesBox,
        action,
    ),
    isViewingResults: isViewingResults(
        (state as TableDisplayOptionsState).isViewingResults,
        action,
    ),
    startsShowingResults: startsShowingResults(
        (state as TableDisplayOptionsState).startsShowingResults,
        action,
    ),
    tableSettings: tableSettingsReducer(
        (state as TableDisplayOptionsState).tableSettings,
        action,
        (state as TableDisplayOptionsState).isViewingResults,
    ),
});

export default persistReducer(
    // @ts-expect-error
    tableDisplayOptionsPersistConfig,
    tableDisplayOptionsReducer,
);
