import {flow, reduce, set, omit, map} from "lodash/fp";
import {createMigrate} from "redux-persist";

import storage from "redux-persist/lib/storage";
import {getObject, removeItem} from "@atg-shared/storage";

export const PERSIST_KEY = "horse.tableSettings";
const migrations = {
    // Change data format to make logic for turning "default on" (e.g. warmupVideo) columns off simpler.
    // ref: [HRS1-5170]
    //
    // from (example):
    // {
    //     startlist: {
    //         selectedColumnIds: ["warmupVideo"] // `true` or `false`
    //     }
    // }
    //
    // to (example):
    // {
    //     startlist: {
    //         selectedColumns: {warmupVideo: true} // `true`, `false` or `undefined` (="unknown" = use default value)
    //     }
    // }
    //
    // This migration can be removed after a few months, let's say after 2020-12-31.
    "0": (state: any) => ({
        ...state,
        startlist: {
            ...state.startlist,
            selectedColumnIds: undefined,
            selectedColumns: reduce(
                (newSelectedColumns, columnId) => ({
                    ...newSelectedColumns,
                    [columnId]: true,
                }),
                {},
                state.startlist.selectedColumnIds,
            ),
        },

        results: {
            ...state.results,
            selectedColumnIds: undefined,
            selectedColumns: reduce(
                (newSelectedColumns, columnId) => ({
                    ...newSelectedColumns,
                    [columnId]: true,
                }),
                {},
                state.results.selectedColumnIds,
            ),
        },

        resultsOverview: {
            ...state.resultsOverview,
            selectedColumnIds: undefined,
            selectedColumns: reduce(
                (newSelectedColumns, columnId) => ({
                    ...newSelectedColumns,
                    [columnId]: true,
                }),
                {},
                state.resultsOverview.selectedColumnIds,
            ),
        },
    }),

    // Special migration logic for `warmupVideo` column + the old `disableWarmUpVideoDefault`.
    // ref: [HRS1-5170]
    //
    // This migration can be removed after a few months, let's say after 2020-12-31.
    "1": (state: any) => {
        // the old data structure for the `warmupVideo` column was... "interesting":
        // if `warmupVideo` existed in the `selectedColumnIds` array, it was "on"
        // if `warmupVideo` didn't exist in the `selectedColumnIds` array, it was "on", unless if
        // `disableWarmUpVideoDefault` was `true`, then if was "off"
        if (
            state.startlist.disableWarmUpVideoDefault &&
            state.startlist.selectedColumns.warmupVideo === undefined
        ) {
            return flow([
                set("startlist.selectedColumns.warmupVideo", false),
                omit(["startlist.disableWarmUpVideoDefault"]),
            ])(state);
        }
        return omit(["startlist.disableWarmUpVideoDefault"], state);
    },

    // Add back "sorted columns" feature after refactoring fluxxor to Redux.
    // ref: [HRS1-10204]
    //
    // from (example):
    // {
    //     startlist: {
    //         selectedColumns: {warmupVideo: true}
    //     }
    // }
    //
    // to (example):
    // {
    //     startlist: {
    //         // now we can keep track of the order the columns were configured (added/removed)
    //         selectedColumns: [{id: "warmupVideo", value: true}]
    //     }
    // }
    //
    // This migration can be removed after a few months, let's say after 2020-12-31.
    "2": (state: any) => {
        const transform = (selectedColumns: any) =>
            map(
                (key) => ({id: key, value: selectedColumns[key]}),
                Object.keys(selectedColumns),
            );

        return flow(
            set("startlist.selectedColumns", transform(state.startlist.selectedColumns)),
            set("results.selectedColumns", transform(state.results.selectedColumns)),
            set(
                "resultsOverview.selectedColumns",
                transform(state.resultsOverview.selectedColumns),
            ),
        )(state);
    },
};

const tableDisplayOptionsPersistConfig = {
    storage,
    key: PERSIST_KEY,
    debug: true,
    version: 2,
    whitelist: ["tableSettings", "hasClosedNewOtherGamesBox"],
    migrate: (state: Record<string, unknown>, currentVersion: number) => {
        let oldState;
        // When a user revisits (=not their first visit) atg.se for the FIRST TIME after we have put
        // redux-persist (for this reducer) into production, we need to "manually" let redux-persist
        // know about the old state from localStorage.
        // This logic can be removed some time in the future when most users have revisited atg.se,
        // or reinstalled their browser, etc.
        if (!state) {
            oldState = getObject("tableSettings");
            removeItem("tableSettings");
        }

        // In all other situations we let `createMigrate` figure out which migrations should be
        // called.
        // @ts-expect-error
        return createMigrate(migrations)(oldState || state, currentVersion);
    },
};

export default tableDisplayOptionsPersistConfig;
