import {filter, difference, reduce} from "lodash/fp";
import {formatCurrency} from "@atg-shared/currency";
import log, {serializeError} from "@atg-shared/log";
import {formatNumber} from "@atg/utils/strings";
import * as Message from "@atg-horse-shared/coupon-messages";
import {type Coupon as CouponType, CouponTypes} from "@atg-horse-shared/coupon-types";
import type {GameType} from "@atg-horse-shared/game-types";
import * as GameUtils from "@atg-horse-shared/utils/game";
import * as Start from "@atg-horse-shared/utils/start";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {ReducedBetsUtils} from "@atg-horse/reduced-bets";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {isReducedCouponTypes} from "@atg-horse/horse-bet";
import {getGameType} from "@atg-horse-shared/utils/game/v3Utils";
import CouponDefs from "../coupon-defs/couponDefs";
import * as Coupon from "./coupon";
import CouponSettings from "./couponSettings";
import * as CouponValidation from "./couponValidation";

/*
 * Note that all state variables in this file are not redux state.
 * Instead it is a flattened slice of redux state that comes from couponrulesReducer running the rules
 * I will not change the names in entire file. Instead I wish we get rid of this functionality and only n redux-saga some day.
 */
export function harryOpenRule(prefix?: string) {
    return function _harryOpenRule(state: any, oldState: any) {
        const {couponRace, coupon} = state;
        const {couponRace: oldCouponRace, coupon: oldCoupon} = oldState;

        if (!oldCouponRace || oldCouponRace === couponRace) return state;
        const oldBets = Coupon.getBetsByPrefix(oldCouponRace, prefix);
        const bets = Coupon.getBetsByPrefix(couponRace, prefix);

        const harryBetMethodChanged = oldCoupon.betMethod !== coupon.betMethod;

        let updatedCoupon = coupon;
        if (bets.length === 0) {
            updatedCoupon = Coupon.updateHarryOpen(coupon, couponRace.id, prefix, true);
        } else if (bets.length > 0 && oldBets.length === 0 && !harryBetMethodChanged) {
            updatedCoupon = Coupon.updateHarryOpen(coupon, couponRace.id, prefix, false);
        }
        return {
            ...state,
            coupon: updatedCoupon,
        };
    };
}

function addMessage(
    state: any,
    message: Message.Message,
    raceId?: string,
    startId?: string,
) {
    return {
        ...state,
        couponValidation: CouponValidation.addMessage(
            state.couponValidation,
            message,
            raceId,
            startId,
        ),
    };
}

export function atLeastOneHarryOpenRace(state: any) {
    const {coupon} = state;
    if (!coupon) return state;

    const harryOpenRaces = filter(Coupon.isHarryOpen, coupon.races);
    if (harryOpenRaces.length > 0) return state;

    return addMessage(
        state,
        Message.error(
            "HarryOpenError",
            "Du måste ha minst en öppen avdelning för att spela Harry Boy",
            null,
        ),
    );
}

function atLeastOneHarryOpenPlacement(prefixes?: Array<string>) {
    return (state: any) => {
        const {couponRace} = state;
        if (!couponRace) return state;

        const numOpenPlacements = reduce(
            (acc, currentPrefix) => {
                const isOpen = Coupon.isHarryOpen(couponRace, currentPrefix);
                return isOpen ? acc + 1 : acc;
            },
            0,
            prefixes,
        );

        if (numOpenPlacements > 0) return state;

        return addMessage(
            state,
            Message.error(
                "HarryOpenError",
                "Du måste ha minst en öppen placering.",
                null,
            ),
        );
    };
}

const reducedCouponAtLeastOnePlayedHorse = (state: any) => {
    const {coupon, reducedBets} = state;
    const reductionTerms = reducedBets[coupon.cid];
    if (
        !ReducedBetsUtils.hasSetRestrictions(reductionTerms) ||
        !reductionTerms.reductionMetadata
    )
        return state;

    const {horseRowCounts} = reductionTerms?.reductionMetadata || {};
    if (ReducedBetsUtils.areAllHorsesUnplayed(horseRowCounts)) {
        return addMessage(
            state,
            Message.error(
                "allUnplayedHorses",
                "Du saknar hästar till ditt spel. Dina villkor ser ut att ha satts för hårt. Vänligen ta bort eller ändra i dina villkor.",
                null,
            ),
        );
    }

    return state;
};

const reducedCouponTooManyRows = (state: any) => {
    const {coupon} = state;
    const {rows} = coupon;

    const isOverRowLimit = rows > 10000000;

    if (isOverRowLimit) {
        return addMessage(
            state,
            Message.error(
                "reducedCouponTooManyRows", // using deprecated_formatCurrency to create spaces between every 3 digits. Have to multiply by
                // 100 since the function converts two last digits to decimals.
                `${formatCurrency(rows * 100, {
                    hideDecimals: true,
                    hideCurrency: true,
                })} rader är tyvärr fler än 10 000 000 och överskrider därmed gränsen.`,
                null,
            ),
        );
    }

    return state;
};

const reducedCouponTooManySystems = (state: any) => {
    const {coupon, game, reducedBets} = state;
    const reductionTerms = reducedBets[coupon.cid];
    const {effectiveSystems} = reductionTerms?.reductionMetadata ?? {};

    // @ts-expect-error not declared
    const isOverNumberOfSystems = effectiveSystems > Coupon.systemsLimit[game?.type];

    if (isOverNumberOfSystems) {
        return addMessage(
            state,
            Message.error(
                "reducedCouponTooManySystems", // using deprecated_formatCurrency to create spaces between every 3 digits. Have to multiply by
                // 100 since the function converts two last digits to decimals.
                `${formatCurrency(effectiveSystems * 100, {
                    hideDecimals: true,
                    hideCurrency: true,
                })} system är tyvärr fler än ${
                    // @ts-expect-error not declared
                    Coupon.systemsLimit[game?.type]
                } och överskrider därmed gränsen.`,
                null,
            ),
        );
    }

    return state;
};

const reducedCouponShowBackendError = (state: any) => {
    const {coupon, reducedBets} = state;
    const reductionTerms = reducedBets[coupon.cid];
    const {error} = reductionTerms?.reductionMetadata ?? {};

    if (error) {
        log.error(`couponRules: reducedCouponShowBackendError`, {
            error: serializeError(error),
        });

        return addMessage(state, Message.error("reductionError", error, null));
    }

    return state;
};

function reductionMetadataCalculationError(state: any) {
    const {reducedBets} = state;

    if (reducedBets.errorResponse) {
        const message =
            reducedBets.errorResponse.meta.code === 0
                ? "Reduceringen misslyckades. Vänligen kontrollera din internetanslutning och försök igen."
                : "Reduceringen misslyckades pga tekniska problem. Vänligen försök igen senare.";

        return addMessage(
            state,
            Message.submitError(
                "reductionMetadataCalculationError",
                message,
                null,
            ) as Message.Message,
        );
    }

    return state;
}

export function baseBetRule(state: any, oldState: any) {
    const {coupon, couponSettings = {}} = state;
    const {couponSettings: oldCouponSettings = {}} = oldState;

    const couponRace = coupon.races[0];
    const {baseBets} = couponRace;
    if (!baseBets || baseBets.length === 0 || couponSettings.showBaseHorses) return state;

    if (oldCouponSettings.showBaseHorses) {
        return {
            ...state,
            coupon: Coupon.updateBets(coupon, couponRace.id, [], "base"),
        };
    }

    return {
        ...state,
        couponSettings: CouponSettings.showBaseHorses(couponSettings, true),
    };
}

function onlyOneBaseBetAllowed(state: any, oldState: any) {
    const {coupon, couponRace, couponSettings} = state;
    const {couponRace: oldCouponRace} = oldState;

    if (!oldCouponRace) return state;

    const {baseBets} = couponRace;
    const {baseBets: oldBaseBets} = oldCouponRace;
    const baseBetsChanged = baseBets !== oldBaseBets;
    const noBaseBets = baseBets.length === 0;
    if (!baseBetsChanged || noBaseBets || !couponSettings.showBaseHorses) return state;

    const baseBetsToSelect = difference(baseBets, oldBaseBets);

    return {
        ...state,
        coupon: Coupon.updateBets(coupon, couponRace.id, baseBetsToSelect, "base"),
    };
}

export function raketBetTypeRule(state: any, oldState: any) {
    const {couponRace} = state;
    const {couponRace: oldCouponRace} = oldState;
    if (oldCouponRace === couponRace) return state;
    const {coupon} = state;
    if (couponRace.bets.length === 0) {
        return {
            ...state,
            coupon: Coupon.updateRace(coupon, couponRace.id, {betType: null}),
        };
    }
    if (!oldCouponRace) return state;

    const betsDiff = difference(couponRace.bets, oldCouponRace.bets);
    const bets = betsDiff.length > 0 ? betsDiff : oldCouponRace.bets;
    const betType = couponRace.betType || "vinnare";

    return {
        ...state,
        coupon: Coupon.updateRace(coupon, couponRace.id, {
            bets,
            betType,
        }),
    };
}

export const createScratchedStartsWarningMessageText = (scratchedSelectedStarts: any) => {
    const isSingleRaceGame = scratchedSelectedStarts.length === 1;
    return scratchedSelectedStarts
        .reduce((texts: any, startIds: any, index: number) => {
            const startNumbers = startIds.map((startId: unknown) =>
                // @ts-expect-error not declared
                Start.getStartNumberFromId(startId),
            );
            if (startNumbers.length === 0) return texts;
            const startNumbersText = startNumbers.join(",");

            const text = isSingleRaceGame
                ? `häst ${startNumbersText}`
                : `häst ${startNumbersText} i avd ${index + 1}`;

            return [...texts, text];
        }, [])
        .join(", ");
};

// fun little hack: add extra wrapper function to delay call to `Coupon.getScratchedStarts` to avoid crash due to circular dependency :thisisfine:
const getScratchedBetsAndReserves = (...args: any) =>
    // @ts-expect-error
    Coupon.getScratchedStarts(Coupon.getBetsAndReserves)(...args);
// @ts-expect-error
const getScratchedBets = (...args) => Coupon.getScratchedStarts(Coupon.getBets)(...args);

function warnIfScratchedBets({includingReserves = false} = {}) {
    return (state: any) => {
        const {coupon, game} = state;
        if (!game) return state;

        const scratchedBets = includingReserves
            ? getScratchedBetsAndReserves(coupon, game)
            : getScratchedBets(coupon, game);
        const hasScratchedBets = Coupon.hasScratchedStarts(scratchedBets);

        if (!hasScratchedBets) return state;

        const selectedHorsesText = createScratchedStartsWarningMessageText(scratchedBets);

        return addMessage(
            state,
            // @ts-expect-error not declared
            Message.submitWarning(
                7,
                `Du har markerat strukna hästar (${selectedHorsesText}). Om du väljer att lämna in systemet ändå kommer reserver/turordning ersätta de strukna hästarna.`,
            ),
        );
    };
}

function noSelectedScratchedReservesAllowed(
    errorMessage = "Struken häst kan ej markeras som reserv.",
) {
    return function _noSelectedScratchedReservesAllowed(state: any) {
        const {game, race, couponRace} = state;
        if (!game) return state;
        const scratchedReservesInCouponRace = Coupon.getScratchedReservesInCouponRace(
            couponRace,
            race,
        );
        if (!scratchedReservesInCouponRace.length) return state;

        const message = Message.error("ScratchedReservesError", errorMessage, {
            prefix: undefined,
        });

        return addMessage(state, message, couponRace.id);
    };
}

function noSelectedScratchedStartsAllowed(
    prefix?: string,
    errorMessage?: string,
    messageCreator: typeof Message.warning | typeof Message.error = Message.error,
) {
    return function _noSelectedScratchedStartsAllowed(state: any) {
        const {game, race, couponRace, couponValidation} = state;
        if (!game) return state;
        const scratchedStartsInCouponRace = Coupon.getScratchedStartsInCouponRaceByPrefix(
            prefix,
        )(couponRace, race);
        if (!scratchedStartsInCouponRace.length) return state;

        const message = messageCreator(
            // @ts-expect-error number should be string
            4,
            errorMessage || "Minst en häst du valt är struken.",
            {prefix},
        );

        // @ts-expect-error
        const newCouponValidation = scratchedStartsInCouponRace.reduce(
            (
                updatedCouponValidation: CouponValidation.CouponValidation,
                startId: string,
            ) =>
                CouponValidation.addMessage(
                    updatedCouponValidation,
                    message,
                    race.id,
                    startId,
                ),
            couponValidation,
        );

        return {
            ...state,
            couponValidation: newCouponValidation,
        };
    };
}

function atLeastOneBetRequired(message = "Du måste välja minst en häst.") {
    return (state: any) => {
        const {couponRace} = state;
        if (couponRace.bets.length > 0) return state;
        return addMessage(
            state,
            Message.submitError(
                // @ts-expect-error number should be string
                3,
                message,
                null,
            ) as Message.Message,
            couponRace.id,
        );
    };
}

function atLeastOneRestrictionTermRequired(state: any) {
    const {coupon, reducedBets} = state;
    const reductionTerms = reducedBets[coupon.cid];

    if (
        !reductionTerms?.reductionMetadata ||
        !ReducedBetsUtils.hasSetRestrictions(reductionTerms)
    ) {
        return addMessage(
            state,
            Message.submitError(
                "privateTeamReducedCouponWithoutRestrictionTerms",
                "För att spela reducerat behöver du ange minst ett villkor.",
                null,
            ) as Message.Message,
            coupon.cid,
        );
    }
    return state;
}

function betsBelowMaxBets(state: any) {
    const {couponRace, race, raceIndex} = state;
    if (couponRace.bets.length === 0 || !race) return state;

    const maxAllowedBets = Coupon.getMaxBetsForRace(race);
    if (maxAllowedBets < couponRace.bets.length) {
        return addMessage(
            state,
            Message.error(
                // @ts-expect-error number should be string
                1,
                `Du har markerat fler hästar än vad som startar i avd ${raceIndex + 1}.`,
                null,
            ),
            couponRace.id,
        );
    }

    return state;
}

function correctNumberTop7Bets(state: any) {
    const {coupon, couponRace} = state;
    const numBets = Coupon.numStartOrderBets(couponRace);
    const {betMethod, banker} = coupon;
    if (betMethod === "harry") {
        if (!banker) return state;
        if (numBets !== 0) return state;
        return addMessage(
            state,
            Message.submitError(
                // @ts-expect-error number should be string
                3,
                "Du måste välja en spik.",
                null,
            ) as Message.Message,
            couponRace.id,
        );
    }

    if (numBets >= 7) return state;
    return addMessage(
        state,
        Message.submitError(
            // @ts-expect-error number should be string
            3,
            "Du måste välja hästar för placering 1-7.",
            null,
        ) as Message.Message,
        couponRace.id,
    );
}

function reserveOneMustBeSelectedIfReserveTwoIsSelected(state: any) {
    const {couponRace} = state;
    const {reserves} = couponRace;
    if (reserves[0] === null && reserves[1] !== null) {
        return addMessage(
            state,
            Message.submitError(
                // @ts-expect-error number should be string
                4,
                "Du måste välja en första reserv om du har valt en andra reserv.",
                null,
            ) as Message.Message,
            couponRace.id,
        );
    }
    return state;
}

const when = (predicate: any) => (rule: any) => (state: any, oldState: any) =>
    predicate(state, oldState) ? rule(state, oldState) : state;

const whenFlex = when(({coupon}: {coupon: CouponType}) => coupon.betMethod === "flex");
const whenHarry = when(({coupon}: {coupon: CouponType}) => coupon.betMethod === "harry");
const whenNotHarry = when(
    ({coupon}: {coupon: CouponType}) => coupon.betMethod !== "harry",
);
const whenFrench = when(({game}: any) => game && game.rules === "french");
const whenReduced = when(({coupon}: {coupon: CouponType}) =>
    isReducedCouponTypes(coupon.type),
);
const whenPrivateTeamReduced = when(
    ({coupon}: {coupon: CouponType}) => coupon.type === CouponTypes.PRIVATE_TEAM_REDUCED,
);

function noBetsAndBaseBetsAtTheSameTime(state: any) {
    const {couponRace} = state;
    if (couponRace.bets.length === 0 || couponRace.baseBets.length === 0) return state;

    return addMessage(
        state,
        Message.error(
            // @ts-expect-error number should be string
            98,
            "Du kan bara markera hästar eller utgångshästar i Harry på Tvilling.",
            null,
        ),
        couponRace.id,
    );
}

function atLeastTwoHorsesOrOneHorseAndOneBaseHorseBetRequired(state: any) {
    const {coupon, couponRace} = state;
    if (coupon.betMethod || coupon.combinations > 0) return state;

    if (couponRace.bets.length > 0 || couponRace.baseBets.length === 0) {
        return addMessage(
            state,
            Message.submitError(
                // @ts-expect-error number should be string
                3,
                "Du måste välja minst två olika hästar.",
                null,
            ) as Message.Message,
            couponRace.id,
        );
    }
    return addMessage(
        state,
        Message.submitError(
            // @ts-expect-error number should be string
            4,
            "Minst en häst måste väljas.",
            null,
        ) as Message.Message,
        couponRace.id,
    );
}

function atLeastOneCombination(state: any) {
    const {coupon} = state;
    if ((coupon.betMethod && coupon.betMethod !== "flex") || coupon.combinations > 0)
        return state;
    return addMessage(
        state,
        Message.submitError(
            // @ts-expect-error number should be string
            3,
            "Du måste välja minst en unik häst för varje placering.",
            null,
        ) as Message.Message,
    );
}

function stakeAboveMinStake(state: any) {
    const {coupon} = state;
    // @ts-expect-error
    const {minStake} = CouponDefs[coupon.game.type];
    if (coupon.stake >= minStake) return state;

    return addMessage(
        state,
        Message.submitError(
            // @ts-expect-error number should be string
            1,
            `Du måste satsa minst ${formatCurrency(minStake, {
                hideDecimals: true,
            })}.`,
            null,
        ) as Message.Message,
    );
}
const MAX_BET_COST = 99999 * 100;

// Check if the cost is above the max Limit
function betCostBelowMaxCost(flatState: any) {
    const {coupon, reducedBets} = flatState;
    const {betMethod, cid, type, betCost} = coupon;

    if (isReducedCouponTypes(type)) {
        const reducedBet = reducedBets[cid];

        if (reducedBet?.reductionMetadata?.willBeRejected) {
            return addMessage(
                flatState,
                Message.submitError(
                    "BetCostAboveMaxCost",
                    `Du har minst en kupong som överskrider maxgränsen på ${formatCurrency(
                        MAX_BET_COST,
                    )}.`,
                    null,
                ) as Message.Message,
            );
        }

        return flatState;
    }

    if (betMethod || betCost <= MAX_BET_COST) return flatState;

    return addMessage(
        flatState,
        Message.error(
            "BetCostAboveMaxCost",
            `Beloppet ${formatCurrency(
                betCost,
            )} är tyvärr högre än maxinsatsen ${formatCurrency(MAX_BET_COST)}.`,
            null,
        ),
    );
}
// TODO remove harry bet limit
function betCostBelowHarryBetLimit(state: any) {
    const {
        coupon: {betMethod, betCost, harryBetLimit},
    } = state;
    if (betMethod !== "harry") return state;
    if (!harryBetLimit || Number.isNaN(harryBetLimit)) return state; // transient state for the app
    if (betCost <= harryBetLimit) return state;

    return addMessage(
        state,
        Message.error(
            // @ts-expect-error number should be string
            9.1,
            "Ditt spel överstiger din valda maxinsats.",
            null,
        ),
    );
}
function enoughBettableRacesForSystem(state: any) {
    const {coupon, game} = state;

    if (!coupon || !game) return state;

    const bettableRaces = filter((couponRace) => {
        const race = GameUtils.getRaceById(game, couponRace.id);
        if (!race) return false;

        return race.status === "upcoming";
    }, coupon.races);
    // @ts-expect-error
    const {requiredBetCount, label} = CouponDefs[coupon.game.type].systems[coupon.system];

    if (bettableRaces.length >= requiredBetCount) return state;

    return addMessage(
        state,
        Message.error(
            // @ts-expect-error number should be string
            12,
            `Den här spelformen (${label}) är inte tillgänglig just nu.`,
            null,
        ),
    );
}

function simpleSystemWithinLimits(state: any) {
    const {coupon} = state;

    if (!coupon || coupon.system !== "simple") return state;

    const numBets = Coupon.getNumBets(coupon);

    if (numBets < 2) {
        return addMessage(
            state,
            Message.submitError(
                // @ts-expect-error number should be string
                10,
                "Du behöver välja hästar i minst två lopp för att spela.",
                null,
            ) as Message.Message,
        );
    }

    if (numBets > 7) {
        return addMessage(
            state,
            Message.error(
                // @ts-expect-error number should be string
                10,
                "Du kan maximalt välja hästar i sju lopp.",
                null,
            ),
        );
    }

    return state;
}

function matchedSystemRequiredRaceCount(state: any) {
    const {coupon} = state;

    if (!coupon || coupon.system === "simple") return state;

    const numBets = Coupon.getNumBets(coupon);
    // @ts-expect-error
    const {requiredBetCount, label} = CouponDefs[coupon.game.type].systems[coupon.system];

    if (numBets < requiredBetCount) {
        return addMessage(
            state,
            Message.submitError(
                // @ts-expect-error number should be string
                10,
                `I den här spelformen (${label}), behöver du välja hästar i ${requiredBetCount} lopp.`,
                null,
            ) as Message.Message,
        );
    }

    if (numBets > requiredBetCount) {
        return addMessage(
            state,
            Message.error(
                // @ts-expect-error number should be string
                10,
                `När du valt system ${label} kan du bara välja hästar i ${requiredBetCount} lopp.`,
                null,
            ),
        );
    }

    return state;
}

function disableReserveMode(state: any) {
    const {couponSettings} = state;
    return {
        ...state,
        couponSettings: CouponSettings.selectReserveMode(couponSettings, false),
    };
}

function couponRule(rule: any) {
    return (flatState: any, oldFlatState: any) => ({
        ...flatState,
        coupon: rule(flatState.coupon, oldFlatState.coupon, flatState.reducedBets),
    });
}

/*
 * will change and attribute in a flat state (which will update redux state if returned in couponrulesReducer)
 */
function couponAttributeRule(attribute: string, rule: any) {
    return couponRule((coupon: any, oldCoupon: any, reducedBets: any) =>
        Coupon.updateCoupon(coupon, {
            [attribute]: rule(coupon, oldCoupon, reducedBets),
        }),
    );
}

function combinationBetCost(coupon: any) {
    if (coupon.betMethod === "harry")
        // @ts-expect-error
        return coupon.combinations * CouponDefs[coupon.game.type].minStake;
    return coupon.combinations * coupon.stake;
}

function trioBetCost(coupon: any) {
    if (coupon.betMethod === "flex") return coupon.flexBetCost;
    return combinationBetCost(coupon);
}

function vinnareOrPlatsBetCost(coupon: any) {
    return coupon.races[0].bets.length * coupon.stake;
}

function vpBetCost(coupon: any) {
    return vinnareOrPlatsBetCost(coupon) * 2;
}

function divisionGameBetCost(coupon: any) {
    if (coupon.type === CouponTypes.REDUCED) {
        // The field `coupon.betCost` is deprecated and for reduced `coupon` it cant be used anymore [HRS1-7166]
        return null;
    }
    return coupon.stake * coupon.rows;
}

function raketBetCost(coupon: any) {
    // @ts-expect-error
    const {systemMultiplicators} = CouponDefs[coupon.game.type];
    return coupon.stake * systemMultiplicators[coupon.system];
}

function trioFlexValue(coupon: any) {
    const {combinations, flexBetCost} = coupon;

    if (!combinations) return 0;

    let percentage = (flexBetCost / combinations).toString();

    // Round the percentage down to one decimal place.
    const decimalIndex = percentage.indexOf(".");
    if (decimalIndex > -1) percentage = percentage.slice(0, decimalIndex + 2);

    return Number(percentage);
}

function flexValueWithinRange(state: any) {
    const {coupon} = state;
    const isFlexValueValid = Coupon.isFlexValueValid(coupon);
    if (isFlexValueValid) return state;

    return addMessage(
        state,
        Message.error(
            // @ts-expect-error number should be string
            5,
            `Vad% måste vara mellan ${formatNumber(
                Coupon.MIN_TRIO_FLEX_VALUE,
            )}% - ${formatNumber(Coupon.MAX_TRIO_FLEX_VALUE)}%`,
            null,
        ),
    );
}

function runRaceRules(state: any, oldState: any, raceIndex: any, rules: any) {
    return rules.reduce((updatedState: any, raceRule: any) => {
        const couponRace = updatedState.coupon.races[raceIndex];
        const oldCouponRace = oldState.coupon ? oldState.coupon.races[raceIndex] : null;
        const oldRace = oldState.game
            ? GameUtils.getRaceById(oldState.game, couponRace.id)
            : null;
        const race = state.game ? GameUtils.getRaceById(state.game, couponRace.id) : null;
        return raceRule(
            {...updatedState, couponRace, race, raceIndex},
            {...oldState, couponRace: oldCouponRace, race: oldRace, raceIndex},
        );
    }, state);
}

function raceRules(...rules: any) {
    return (state: any, oldState: any) => {
        const couponRaces = state.coupon.races || [];
        return couponRaces.reduce(
            (stateAfterRaceRule: any, _couponRace: any, raceIndex: any) =>
                runRaceRules(stateAfterRaceRule, oldState, raceIndex, rules),
            state,
        );
    };
}
function runRules(state: any, oldState: any, rules: any) {
    return rules.reduce(
        (stateAfterRule: any, rule: any) => rule(stateAfterRule, oldState),
        state,
    );
}
const getStandardCost = (coupon: CouponType) => {
    if (coupon.type === CouponTypes.REDUCED) {
        return null;
    }
    return coupon.betCost * (coupon.systems || 1);
};

const getTrioCost = (coupon: any) => {
    if (coupon.betMethod === "flex") return coupon.flexBetCost;
    return getStandardCost(coupon);
};

/**
 * We are migrating away from "cached" cost calculations here in couponrules. Since the cost can
 * always be derived from the coupon + other state data, it should be calculated on-the-fly as
 * needed instead. This will avoid stale data, race conditions, etc. However, it's a fairly big task
 * that we're doing step by step, and this temporary rule makes sure we don't accidentally use the
 * old values in places that are already migrated (e.g. `betCost` for reduced coupons is no longer
 * valid).
 *
 * In order to work efficiently this rule should always come last in a chain, so it can overwrite
 * any previous values.
 *
 * See: [HRS1-7166]
 */
const removeDeprecatedCostAttributes = (flatState: any) => {
    const {coupon} = flatState;

    // for now we've refactored reduced coupons to not use `betCost` and `cost`, so null those
    // values here to make sure we don't accidentally use them (which would show the **wrong cost**)
    if (isReducedCouponTypes(coupon.type)) {
        coupon.betCost = null;
        coupon.cost = null;
    }

    return flatState;
};

const divisionGameRules = [
    whenHarry(disableReserveMode),
    raceRules(
        whenNotHarry(
            atLeastOneBetRequired("Du måste välja minst en häst i varje avdelning."),
        ),
        betsBelowMaxBets,
        harryOpenRule(),
        noSelectedScratchedReservesAllowed(),
        whenHarry(
            noSelectedScratchedStartsAllowed(
                undefined,
                "Du har markerat en struken häst och spelar Harry Boy.",
                Message.warning,
            ),
        ),
    ),
    whenHarry(atLeastOneHarryOpenRace),
    whenPrivateTeamReduced(atLeastOneRestrictionTermRequired),
    whenReduced(reducedCouponAtLeastOnePlayedHorse),
    whenReduced(reducedCouponTooManyRows),
    whenReduced(reducedCouponTooManySystems),
    whenReduced(reducedCouponShowBackendError),
    whenReduced(reductionMetadataCalculationError),
    couponAttributeRule("rows", Coupon.numDivisionGameRows),
    couponAttributeRule("betCost", divisionGameBetCost),
    betCostBelowMaxCost,
    betCostBelowHarryBetLimit,
    whenNotHarry(warnIfScratchedBets()),
    couponAttributeRule("cost", getStandardCost),
    removeDeprecatedCostAttributes,
];

const v3LegacyRules = [
    raceRules(
        atLeastOneBetRequired("Du måste välja minst en häst i varje avdelning."),
        betsBelowMaxBets,
        noSelectedScratchedStartsAllowed(undefined, "Hästen du valt är struken."),
    ),
    whenReduced(reducedCouponAtLeastOnePlayedHorse),
    whenReduced(reducedCouponTooManyRows),
    whenReduced(reducedCouponTooManySystems),
    whenReduced(reducedCouponShowBackendError),
    whenReduced(reductionMetadataCalculationError),
    couponAttributeRule("rows", Coupon.numDivisionGameRows),
    couponAttributeRule("betCost", divisionGameBetCost),
    betCostBelowMaxCost,
    couponAttributeRule("cost", getStandardCost),
    removeDeprecatedCostAttributes,
];

const dubbelRules = [
    raceRules(
        whenNotHarry(
            atLeastOneBetRequired("Du måste välja minst en häst i varje avdelning."),
        ),
        betsBelowMaxBets,
        harryOpenRule(),
        whenNotHarry(
            noSelectedScratchedStartsAllowed(undefined, "Hästen du valt är struken."),
        ),
        whenHarry(
            noSelectedScratchedStartsAllowed(
                undefined,
                "Du har markerat en struken häst och spelar Harry Boy.",
            ),
        ),
    ),
    whenHarry(atLeastOneHarryOpenRace),
    whenReduced(reducedCouponAtLeastOnePlayedHorse),
    whenReduced(reducedCouponTooManyRows),
    whenReduced(reducedCouponTooManySystems),
    whenReduced(reducedCouponShowBackendError),
    whenReduced(reductionMetadataCalculationError),
    couponAttributeRule("rows", Coupon.numDivisionGameRows),
    couponAttributeRule("betCost", divisionGameBetCost),
    betCostBelowMaxCost,
    betCostBelowHarryBetLimit,
    couponAttributeRule("cost", getStandardCost),
    removeDeprecatedCostAttributes,
];

/**
 * All the rules for specific gameTypes
 * This updates the betCost based on game mode (harry, flex etc),
 * It checks if the coupon that is being validated follows the game rules,
 * the betLimits, and applies fee when a coupon is feeable
 *
 * Each attribute has it own rule for the coupon that is being validated
 */
const rules = {
    V75: divisionGameRules,
    V86: divisionGameRules,
    GS75: divisionGameRules,
    V64: divisionGameRules,
    V65: divisionGameRules,
    V5: divisionGameRules,
    V4: divisionGameRules,
    V3Legacy: v3LegacyRules,
    V3: divisionGameRules,
    dd: dubbelRules,
    ld: dubbelRules,
    vinnare: [
        couponAttributeRule("betCost", vinnareOrPlatsBetCost),
        betCostBelowMaxCost,
        stakeAboveMinStake,
        raceRules(atLeastOneBetRequired(), noSelectedScratchedStartsAllowed()),
        couponAttributeRule("cost", getStandardCost),
    ],
    plats: [
        couponAttributeRule("betCost", vinnareOrPlatsBetCost),
        betCostBelowMaxCost,
        stakeAboveMinStake,
        raceRules(atLeastOneBetRequired(), noSelectedScratchedStartsAllowed()),
        couponAttributeRule("cost", getStandardCost),
    ],
    vp: [
        couponAttributeRule("betCost", vpBetCost),
        betCostBelowMaxCost,
        stakeAboveMinStake,
        raceRules(atLeastOneBetRequired(), noSelectedScratchedStartsAllowed()),
        couponAttributeRule("cost", getStandardCost),
    ],
    tvilling: [
        raceRules(baseBetRule),
        couponAttributeRule("combinations", Coupon.numTvillingCombinations),
        couponAttributeRule("betCost", combinationBetCost),
        betCostBelowMaxCost,
        betCostBelowHarryBetLimit,
        stakeAboveMinStake,
        raceRules(
            atLeastTwoHorsesOrOneHorseAndOneBaseHorseBetRequired,
            whenHarry(noBetsAndBaseBetsAtTheSameTime),
            noSelectedScratchedStartsAllowed(),
            noSelectedScratchedStartsAllowed("base"),
            whenFrench(onlyOneBaseBetAllowed),
        ),
        couponAttributeRule("cost", getStandardCost),
    ],
    komb: [
        raceRules(harryOpenRule("firstPlace"), harryOpenRule("secondPlace")),
        couponAttributeRule("combinations", Coupon.numKombCombinations),
        couponAttributeRule("betCost", combinationBetCost),
        betCostBelowMaxCost,
        betCostBelowHarryBetLimit,
        atLeastOneCombination,
        stakeAboveMinStake,
        raceRules(
            noSelectedScratchedStartsAllowed("firstPlace"),
            noSelectedScratchedStartsAllowed("secondPlace"),
        ),
        couponAttributeRule("cost", getStandardCost),
        whenHarry(atLeastOneHarryOpenPlacement(["firstPlace", "secondPlace"])),
    ],
    trio: [
        raceRules(
            harryOpenRule("firstPlace"),
            harryOpenRule("secondPlace"),
            harryOpenRule("thirdPlace"),
        ),
        couponAttributeRule("combinations", Coupon.numTrioCombinations),
        couponAttributeRule("betCost", trioBetCost),
        couponAttributeRule("flexValue", trioFlexValue),
        atLeastOneCombination,
        betCostBelowMaxCost,
        betCostBelowHarryBetLimit,
        stakeAboveMinStake,
        raceRules(
            noSelectedScratchedStartsAllowed("firstPlace"),
            noSelectedScratchedStartsAllowed("secondPlace"),
            noSelectedScratchedStartsAllowed("thirdPlace"),
        ),
        couponAttributeRule("cost", getTrioCost),
        whenFlex(flexValueWithinRange),
        whenHarry(
            atLeastOneHarryOpenPlacement(["firstPlace", "secondPlace", "thirdPlace"]),
        ),
    ],
    raket: [
        raceRules(
            raketBetTypeRule,
            noSelectedScratchedStartsAllowed(undefined, "Hästen du valt är struken."),
        ),
        couponAttributeRule("betCost", raketBetCost),
        couponAttributeRule("cost", getStandardCost),
        enoughBettableRacesForSystem,
        simpleSystemWithinLimits,
        matchedSystemRequiredRaceCount,
        betCostBelowMaxCost,
        stakeAboveMinStake,
    ],
    top7: [
        couponAttributeRule("betCost", (coupon: CouponType) => coupon.stake),
        couponAttributeRule("combinations", Coupon.numTop7Combinations),
        betCostBelowMaxCost,
        raceRules(
            whenNotHarry(reserveOneMustBeSelectedIfReserveTwoIsSelected),
            correctNumberTop7Bets,
            whenHarry(
                noSelectedScratchedStartsAllowed(
                    undefined,
                    "Det går inte att spela TOP 7 Smart Autofyll med en struken häst som vinnare.",
                ),
            ),
        ),
        whenNotHarry(warnIfScratchedBets({includingReserves: true})),
        whenNotHarry(couponAttributeRule("systemId", Coupon.getSystemId)),
        couponAttributeRule("cost", (coupon: CouponType) => coupon.stake),
    ],
};

/**
 * Get the specific rule for each gameType that is defined in the rules array
 * and run the rules
 *
 * @param {Object} flatState the new flat state slice from couponrulesReducer
 * @param {Object} oldFlatState the old lat state slice from couponrulesReducer
 */
export default function couponRules(flatState: any, oldFlatState: any) {
    // Varenne V3 Remove this variable when cleaning up V3Legacy
    // Harry products don't have game so it crashes
    const gameType = flatState.game
        ? getGameType(flatState.game)
        : (flatState.coupon.game.type as GameType);
    // Varenne V3 Revert this to {rules[flatState.coupon.game.type as GameType]} when V3Legacy support is dropped
    return runRules(flatState, oldFlatState, rules[gameType as GameType]);
}
