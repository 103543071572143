import {find, head, identity, tail} from "lodash";
import {call, put, take, select, takeLatest} from "redux-saga/effects";
import dayjs from "dayjs";
import atgRequest from "@atg-shared/fetch";
import {serverTime} from "@atg-shared/server-time";
import * as AuthActions from "@atg-shared/auth/domain/authActions";
import {subscribe} from "@atg-frame-shared/push";
import {MEDIA_SERVICE_URL} from "@atg-shared/service-url";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {fetchCountryAction} from "@atg-horse-shared/country";
import log, {serializeError} from "@atg-shared/log";
import {RouterSelectors} from "@atg-shared/router";
import {LOGIN_FINISHED, LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CalendarActions from "@atg-horse-shared/calendar/domain/calendarActions";
import {getSelectedChannel, NO_CHANNEL_ID} from "../player/videoSelectors";
import {
    LOAD_VIDEO_CHANNELS_START,
    START_LISTENING_TO_CHANNELS_PUSH,
    STOP_LISTENING_TO_CHANNELS_PUSH,
    LOAD_CHANNELS_EXTERNAL_WINDOW,
    RECEIVE_CHANNELS_PUSH,
} from "./videoActionConstants";
import {loadedChannelConfig, receiveChannelsPush} from "./videoActions";
import {getQualityFromStreamId} from "./videoutil";

const BONSAI_LIVE_STREAMS_URL = `${MEDIA_SERVICE_URL}/broadcasts`;

const PUSH_TOPIC = "racinginfo/broadcasts";

function getLiveStreams() {
    const liveStreamsURL = BONSAI_LIVE_STREAMS_URL;
    return atgRequest(liveStreamsURL);
}
// @ts-expect-error
export function getChannel(streamId, channels) {
    return find(channels, (channel) => find(channel.streams, {id: streamId}));
}

// popup video flow, it is used instead of loadChannels in the normal flow
// @ts-expect-error
export function* externalWindowFlow({payload: streamId}) {
    yield put(
        CalendarActions.fetchCalendar(
            dayjs(serverTime(false)).format("YYYY-MM-DD"),
            undefined,
            true,
        ),
    );
    // @ts-expect-error
    const channelsResponse = yield call(getLiveStreams);
    const channels = channelsResponse.data;

    yield put(fetchCountryAction());

    yield put(AuthActions.checkAuth(false));
    yield take([AuthActions.AUTH_CHECK_RESPONSE, AuthActions.AUTH_SET_ROLES]);
    // @ts-expect-error
    const selectedChannel = yield call(getChannel, streamId, channels);
    if (!selectedChannel) {
        // Splunk logs show that selectedChannel can be null.
        return;
    }
    const payload = {
        channels,
        selectedChannel: {
            id: selectedChannel.id,
            name: selectedChannel.name,
        },
        selectedQuality: getQualityFromStreamId(streamId),
    };

    yield put(loadedChannelConfig(payload));
}
// @ts-expect-error
export function* loadChannels(action) {
    try {
        // @ts-expect-error
        let selectedChannel = yield select(getSelectedChannel);

        // Avoid unnecessary request from channel push
        if (
            selectedChannel.name !== "Ingen livevideo" &&
            action.type === RECEIVE_CHANNELS_PUSH
        ) {
            return;
        }
        // Get the channels from the media api can return error if use has no network
        // @ts-expect-error
        const channelsResponse = yield call(getLiveStreams);
        const channels = channelsResponse.data;

        yield put(fetchCountryAction());

        if (selectedChannel.id === NO_CHANNEL_ID) selectedChannel = head(channels);

        // Tech debt: Sometimes there is a race condition between LivePage's select of channel via the url
        // and this function loading the default channel, when linking to e.g /live/gävle from a hero banner
        // Ideally this should be looked into and fixed but this hack fixes it for now so we can link a banner
        // to the Hästgalan.
        // TODO: clean this up
        // @ts-expect-error
        const path = yield select(RouterSelectors.getPath);
        if (path?.includes("live")) {
            let channelId = tail(path.split("/").filter(identity));
            // @ts-expect-error
            channelId = decodeURIComponent(channelId).toLowerCase();
            const channel = channels.find(
                // @ts-expect-error
                ({id, name}) => id === channelId || name.toLowerCase() === channelId,
            );

            if (channel) {
                selectedChannel = channel;
            }
        }
        yield put(
            // @ts-expect-error
            loadedChannelConfig({
                channels,
                selectedChannel: {
                    name: selectedChannel.name,
                    id: selectedChannel.id,
                },
            }),
        );
    } catch (err: unknown) {
        log.warn("videoSaga.js loadChannels(): network error", {
            error: serializeError(err),
        });
    }
}

// push flow that receives the new channels as they are updated on the backend
// overwrites the `channels` data received first from `loadChannels` or `externalWindowFlow`
// @ts-expect-error
export const getMessageCallback = (dispatch) => (liveStreams) =>
    dispatch(receiveChannelsPush(liveStreams));
// @ts-expect-error
export function* listenToChannelPush(dispatch, subscribeFn = subscribe) {
    // @ts-expect-error
    const messageCallback = yield call(getMessageCallback, dispatch);
    // @ts-expect-error
    const unsubscribe = yield call(subscribeFn, PUSH_TOPIC, messageCallback);

    yield put(fetchCountryAction());

    yield take(STOP_LISTENING_TO_CHANNELS_PUSH);
    yield call(unsubscribe);
}
// @ts-expect-error
export default function* videoSaga(dispatch) {
    yield takeLatest(
        [
            LOAD_VIDEO_CHANNELS_START, // Checking logout and login actions since the video
            // has different feature based on login state,
            LOGOUT_FINISHED,
            LOGIN_FINISHED, // Listening to channels from broadcast api,
            // added here to reconnect VideoFrame for user that lost
            // connection without refreshing the page
            // API endpoint: `/racinginfo/v1/api/media/broadcast`
            RECEIVE_CHANNELS_PUSH,
        ],
        loadChannels,
    );
    // @ts-expect-error
    yield takeLatest([LOAD_CHANNELS_EXTERNAL_WINDOW], externalWindowFlow);
    yield takeLatest(
        [START_LISTENING_TO_CHANNELS_PUSH],
        listenToChannelPush,
        // @ts-expect-error
        dispatch,
        subscribe,
    );
}
