import {combineReducers, type Reducer} from "redux";
import {filter} from "lodash";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import type {LogoutFinishedAction} from "@atg-global-shared/user/userActions";
import {DepositMethods} from "@atg-payment-shared/deposit-utils";
import {DepositActionConstants} from "@atg-payment-shared/deposit-types";
import type {
    ClearIframeDepositAction,
    SetSwishDirectOrderIdAction,
    StartDepositFlowAction,
    DepositAction,
    SetDepositAmountAction,
    SetIframeDepositStateAction,
    SetSuccessfullDepositMethod,
    SwishDirectStepAction,
    SetSwishDirectPhoneNumberAction,
} from "../actions/actionTypes";
import type {
    CardOptionsState,
    DepositIframeState,
    DepositOption,
    SelectedOptionState,
    StatusMessageState,
} from "../domainTypes";

const isLoading: Reducer<boolean, DepositAction> = (state = true, action) => {
    switch (action.type) {
        case DepositActionConstants.START_DEPOSIT_FLOW:
        case DepositActionConstants.FETCH_OPTIONS:
            return true;
        case DepositActionConstants.FETCH_OPTIONS_SUCCESS:
        case DepositActionConstants.FETCH_OPTIONS_FAILURE:
        case DepositActionConstants.DEPOSIT_BLOCKED:
        case DepositActionConstants.ABORT_TRUSTLY_DEPOSIT:
            return false;
        default:
            return state;
    }
};

const isPending: Reducer<boolean, DepositAction | LogoutFinishedAction> = (
    state = false,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.DEPOSIT_START:
        case DepositActionConstants.DEPOSIT_FINALIZE:
        case DepositActionConstants.DEPOSIT_FINALIZE_IFRAME:
        case DepositActionConstants.DELETE_BANK_CARD:
            return true;
        case DepositActionConstants.DEPOSIT_SUCCESS:
        case DepositActionConstants.DEPOSIT_FAILURE:
        case DepositActionConstants.DEPOSIT_BLOCKED:
        case DepositActionConstants.DEPOSIT_PENDING:
        case DepositActionConstants.POPUP_BLOCKED:
        case DepositActionConstants.DELETE_BANK_CARD_SUCCESS:
        case DepositActionConstants.DELETE_BANK_CARD_FAILURE:
        case DepositActionConstants.DEPOSIT_CLOSED:
        case DepositActionConstants.RESET_DEPOSIT_IS_PENDING:
        case DepositActionConstants.ABORT_TRUSTLY_DEPOSIT:
        case LOGOUT_FINISHED:
            return false;

        default:
            return state;
    }
};

const isBlocked: Reducer<boolean, DepositAction> = (state = false, action) => {
    switch (action.type) {
        case DepositActionConstants.DEPOSIT_BLOCKED:
            return true;
        default:
            return state;
    }
};

const cardOptions: Reducer<CardOptionsState, DepositAction> = (
    state = [],
    action,
): CardOptionsState => {
    switch (action.type) {
        case DepositActionConstants.FETCH_OPTIONS_SUCCESS:
            return action.payload.options.cards;
        case DepositActionConstants.FETCH_OPTIONS_FAILURE:
            return [];
        case DepositActionConstants.DELETE_BANK_CARD_SUCCESS:
            return filter(state, {id: DepositMethods.newCard});
        default:
            return state;
    }
};

const trustlyOption: Reducer<DepositOption | null, DepositAction> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.FETCH_OPTIONS_SUCCESS: {
            const {options} = action.payload;
            const trustly = options.trustly[0];

            return trustly || state;
        }

        case DepositActionConstants.FETCH_OPTIONS_FAILURE:
        default:
            return state;
    }
};

const swishDirectOption: Reducer<DepositOption | null | undefined, DepositAction> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.FETCH_OPTIONS_SUCCESS: {
            const {swish} = action.payload.options;

            const newState = Array.isArray(swish)
                ? swish.find((option) => option.id === "swish-e-commerce")
                : state;

            // BE sends id as "swish-e-commerce" but we want it as "swish"
            if (newState?.id === "swish-e-commerce") newState.id = DepositMethods.swish;

            return newState;
        }
        default:
            return state;
    }
};

const selectedOption: Reducer<
    SelectedOptionState | null,
    DepositAction | LogoutFinishedAction
> = (state = null, action) => {
    switch (action.type) {
        case DepositActionConstants.SET_SELECTED_OPTION:
            return {...action.payload, storeCard: action.payload?.storeCard ?? true};
        case DepositActionConstants.DEPOSIT_FLOW_FINISHED:
        case LOGOUT_FINISHED:
            return null;
        default:
            return state;
    }
};

const statusMessage: Reducer<StatusMessageState | null, DepositAction> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.DEPOSIT_SUCCESS:
        case DepositActionConstants.DEPOSIT_FAILURE:
        case DepositActionConstants.DELETE_BANK_CARD_SUCCESS:
        case DepositActionConstants.DELETE_BANK_CARD_FAILURE:
        case DepositActionConstants.POPUP_BLOCKED:
        case DepositActionConstants.DEPOSIT_PENDING:
        case DepositActionConstants.FETCH_OPTIONS_FAILURE:
            return action.payload?.message;
        case DepositActionConstants.START_DEPOSIT_FLOW:
        case DepositActionConstants.START_DEPOSIT_FLOW_IN_MODAL:
        case DepositActionConstants.DEPOSIT_FLOW_STARTED:
        case DepositActionConstants.CLEAR_STATUS_MESSAGES:
        case DepositActionConstants.DEPOSIT_SWISH_DIRECT_START:
            return null;
        default:
            return state;
    }
};

const amount: Reducer<string, DepositAction | LogoutFinishedAction> = (
    state = "",
    action,
) => {
    const {type} = action;

    switch (type) {
        case DepositActionConstants.SET_DEPOSIT_AMOUNT:
            return action.payload?.selectedAmount?.toString() || state;
        case DepositActionConstants.START_DEPOSIT_FLOW:
            return action.payload?.selectedAmount
                ? action.payload.selectedAmount.toString()
                : state;
        case DepositActionConstants.DEPOSIT_FLOW_FINISHED:
        case LOGOUT_FINISHED:
            return "";
        default:
            return state;
    }
};

const depositIframe: Reducer<
    DepositIframeState | null,
    SetIframeDepositStateAction | LogoutFinishedAction | ClearIframeDepositAction
> = (state = null, action) => {
    switch (action.type) {
        case DepositActionConstants.SET_IFRAME_DEPOSIT_STATE:
            return action.payload;
        case DepositActionConstants.CLEAR_IFRAME_DEPOSIT_STATE:
            return null;
        case LOGOUT_FINISHED:
            return null;
        default:
            return state;
    }
};

const successfullPaymentMethodId: Reducer<string, SetSuccessfullDepositMethod> = (
    state = "",
    action,
) => {
    if (action.type === DepositActionConstants.SET_SUCCESSFULL_DEPOSIT_METHOD) {
        return action.payload;
    }
    return state;
};

const swishDirectStep: Reducer<string, SwishDirectStepAction | DepositAction> = (
    state = "",
    action,
) => {
    if (action.type === DepositActionConstants.SET_SWISH_DIRECT_STEP) {
        return action.payload;
    }
    return state;
};

const swishDirectPhoneNumber: Reducer<string, SetSwishDirectPhoneNumberAction> = (
    state = "",
    action,
) => {
    if (action.type === DepositActionConstants.SET_SWISH_DIRECT_PHONE_NUMBER) {
        return action.payload;
    }
    return state;
};

const swishDirectOrderId: Reducer<string, SetSwishDirectOrderIdAction> = (
    state = "",
    action,
) => {
    if (action.type === DepositActionConstants.SET_SWISH_DIRECT_ID) {
        return action.payload.swishDirectOrderId;
    }
    return state;
};

const options = combineReducers({
    cardOptions,
    swishDirectOption,
    trustlyOption,
});

const depositReducer = combineReducers({
    isLoading,
    isPending,
    isBlocked,
    options,
    selectedOption,
    statusMessage,
    amount,
    depositIframe,
    successfullPaymentMethodId,
    swishDirectStep,
    swishDirectPhoneNumber,
    swishDirectOrderId,
});

export default depositReducer;
