import {find, includes} from "lodash";
import type {Game, RaceId} from "@atg-horse-shared/racing-info-api/game/types";
import {GameTypes, type GameType} from "@atg-horse-shared/game-types";
import {
    type VXYSelections,
    type CouponTeamVXYRace,
    type TrioSelections,
    type CouponTeamHorse,
    type KombSelections,
    type TvilingSelections,
    type SingleRaceSelection,
    type Selections,
    SINGLE_RACE_SHARED_BET_GAMES,
} from "@atg-tillsammans-shared/shared-bet-types";
import type {PoolParticipation} from "@atg-horse-shared/bet-types";

export const isSingleRaceSharedBetGameType = (gameType: GameType): boolean =>
    includes(SINGLE_RACE_SHARED_BET_GAMES, gameType);

export const isNotSingleRaceSharedBetGameType = (gameType: GameType): boolean =>
    !isSingleRaceSharedBetGameType(gameType);

export const mapVXYRaces = ({legs}: VXYSelections): Array<CouponTeamVXYRace> =>
    Object.keys(legs).map((raceNumber) => {
        const {bankerPickHorse} = legs[raceNumber];
        return {
            raceNumber: +raceNumber,
            bets: legs[raceNumber].marks.map((mark: number) => ({
                number: mark,
                horse: {name: bankerPickHorse ?? ""},
            })),
            reserves: legs[raceNumber].reserves.map((mark: number) => ({
                number: mark,
            })),
        };
    });

type Race = {
    id: RaceId;
    trackId?: number;
    raceNumber?: number;
    mediaId?: string;
    horses: Record<number, CouponTeamHorse>;
};

const getRace = (game: Game, raceId: RaceId): Race => {
    const race = find(game?.races ?? [], ({id}) => raceId === id);
    if (!race) return {id: raceId, horses: {}};
    return {
        id: raceId,
        raceNumber: race.number,
        trackId: race.track.id,
        mediaId: race.mediaId,
        horses:
            race.starts?.reduce((acc, start) => {
                if (!start) return acc;
                return {
                    ...acc,
                    [start.number]: {
                        id: start.horse?.id,
                        name: start.horse?.name ?? "",
                        nationality: start.horse?.nationality,
                    },
                };
            }, {}) ?? {},
    };
};

const mapBets =
    (horses: Record<number, CouponTeamHorse>) => (selection: SingleRaceSelection) =>
        selection.marks.map((number, index) => ({
            number,
            horse: horses[number] ?? {
                name: index === 0 ? selection.bankerPickHorse ?? "" : "",
            },
        }));

export const mapTrioRaces = ({
    game,
    selections: {id: raceId, positions},
}: {
    game: Game;
    selections: TrioSelections;
}) => {
    const {id, trackId, raceNumber, mediaId, horses} = getRace(game, raceId as RaceId);
    const toBets = mapBets(horses);
    return [
        {
            id,
            trackId,
            raceNumber,
            mediaId,
            firstPlaceBets: toBets(positions["1"]),
            secondPlaceBets: toBets(positions["2"]),
            thirdPlaceBets: toBets(positions["3"]),
        },
    ];
};

export const mapKombRaces = ({
    game,
    selections: {id: raceId, positions},
}: {
    game: Game;
    selections: KombSelections;
}) => {
    const {id, trackId, raceNumber, mediaId, horses} = getRace(game, raceId as RaceId);
    const toBets = mapBets(horses);
    return [
        {
            id,
            trackId,
            raceNumber,
            mediaId,
            firstPlaceBets: toBets(positions["1"]),
            secondPlaceBets: toBets(positions["2"]),
        },
    ];
};

export const mapTvillingRaces = ({
    game,
    selections: {
        id: raceId,
        marks,
        marksBankerPickHorse = "",
        baseMarks,
        baseMarksBankerPickHorse = "",
    },
}: {
    game: Game;
    selections: TvilingSelections;
}) => {
    const {id, trackId, raceNumber, mediaId, horses} = getRace(game, raceId as RaceId);
    return [
        {
            id,
            trackId,
            raceNumber,
            mediaId,
            bets: marks?.map((number, index) => ({
                number,
                horse: horses[number] ?? {
                    name: index === 0 ? marksBankerPickHorse : "",
                },
            })),
            baseBets: baseMarks?.map((number, index) => ({
                number,
                horse: horses[number] ?? {
                    name: index === 0 ? baseMarksBankerPickHorse : "",
                },
            })),
        },
    ];
};

export const mapRaces = (game: Game, selections: Selections) => {
    switch (game.type) {
        case GameTypes.trio: {
            return mapTrioRaces({
                game,
                selections: selections as TrioSelections,
            });
        }
        case GameTypes.komb: {
            return mapKombRaces({
                game,
                selections: selections as KombSelections,
            });
        }
        case GameTypes.tvilling: {
            return mapTvillingRaces({
                game,
                selections: selections as TvilingSelections,
            });
        }
        default: {
            return mapVXYRaces(selections as VXYSelections);
        }
    }
};

export const mapSelections = (
    game: Game,
    selections: Selections,
): {races: Array<unknown>; poolParticipation?: PoolParticipation} => {
    switch (game.type) {
        case GameTypes.trio:
        case GameTypes.komb:
        case GameTypes.tvilling: {
            return {
                races: mapRaces(game, selections),
            };
        }
        default: {
            return {
                races: mapVXYRaces(selections as VXYSelections),
                poolParticipation:
                    (selections as VXYSelections)?.poolParticipation ?? undefined,
            };
        }
    }
};
