import {memoize} from "lodash";
import {
    times,
    every,
    omit,
    flatten,
    head,
    find,
    flow,
    filter,
    sortBy,
    includes,
    flatMap,
    findIndex,
    identity,
    isEmpty,
    get,
    reverse,
    compact,
    map,
    uniqBy,
    uniq,
} from "lodash/fp";
import {
    isVXYGameType,
    type GameType,
    type Big9,
    GameTypes,
} from "@atg-horse-shared/game-types";
import type {CalendarAPITypes, GameAPITypes} from "@atg-horse-shared/racing-info-api";
import {formatCurrency} from "@atg-shared/currency";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {gameDefs, type TOP7_PRO} from "@atg-horse-shared/game-defs";
import {t} from "@lingui/macro";
import {parseStartId} from "../start/start";
import type {
    ComboPoolRaceResult,
    DivisionGameRaceResult,
    DubbelRaceResult,
    PlatsRaceResult,
    PoolResult,
    RaceResult,
    Top7RaceResult,
    VPRaceResult,
    VinnareRaceResult,
} from "../types";
import * as Internals from "./internals";
// eslint-disable-next-line import/no-cycle
import * as Checks from "./checks";

dayjs.extend(isSameOrAfter);

type PoolWinnersExtractor = (poolType: string, winners: Array<any>) => Array<PoolResult>;

/**
 * Get the short user-friendly version of a game type, e.g. "LD" instead of "Lunch Dubbel"
 *
 * The normal version is `getNameFromGameType`, and an even shorter version is `getMobileNameFromGameType`.
 *
 * @todo consider merging this with `getMobileNameFromGameType` and `getMergedPoolsShortName`
 */

export function getShortNameFromGameType(
    gameType: GameType | Big9 | typeof TOP7_PRO,
): string {
    const gameDef = gameDefs[gameType as GameType];
    return gameDef.shortName || gameDef.name;
}
/**
 * Get user-friendly name of a game type, e.g. "Lunch Dubbel" instead of "ld"
 *
 * A slightly shorter version is `getShortNameFromGameType`, and an even shorter version is `getMobileNameFromGameType`.
 */

export function getNameFromGameType(gameType: GameType | Big9 | typeof TOP7_PRO): string {
    return gameDefs[gameType as GameType].name;
}

/**
 * Returns track names for multi-leg games, add race number string for singles.
 * @param game
 * @returns string
 */

export function getTracksAndRaceNumberLabel(game: GameAPITypes.Game) {
    const tracksNames = getTracksNames(game);
    return Checks.isSingleRace(game) && game.races[0].number
        ? `${tracksNames} Lopp ${game.races[0].number}`
        : tracksNames;
}

/**
 * Returns gameDate for regular and harry page games
 * use game.date if exists (e.g. racinginfo response for startlists), otherwise use date of the first race, otherwise (e.g. harry page on ) default to startTime
 * @param game
 * @returns string
 */

export const getGameDate = (game: GameAPITypes.Game) =>
    Checks.isGameWithDate(game)
        ? game.date
        : game.races[0]?.date || getGameStartTime(game);

/**
 * Gets races from game object
 * @param game
 * @returns GameAPITypes.GameRace[]
 */

export function getRaces(game: GameAPITypes.Game): Array<GameAPITypes.GameRace> {
    return get("races", game);
}

export function getFirstRace(game: GameAPITypes.Game): GameAPITypes.GameRace {
    const races = getRaces(game);
    // @ts-expect-error missing properties for race
    if (!Array.isArray(races)) return races.at(0); // Todo: Remove when Backbone is eradicated

    return races[0];
}
export function getGameType(
    game: GameAPITypes.Game | GameAPITypes.GameInfo | CalendarAPITypes.CalendarGame,
): GameType {
    return (
        (game as GameAPITypes.Game).type ||
        (typeof (game as GameAPITypes.GameInfo | CalendarAPITypes.CalendarGame)
            .gameType === "string" &&
            (game as GameAPITypes.GameInfo | CalendarAPITypes.CalendarGame).gameType) ||
        (game as GameAPITypes.GameInfo).gameType.type
    );
}

export function getName(game: GameAPITypes.Game | GameAPITypes.GameInfo): string {
    return getNameFromGameType(getGameType(game));
}

export function getNumberOfRacesFromGameType(gameType: GameType): number {
    return gameDefs[gameType].numberOfRaces || 1;
}

export function getNameIncludingRaceIdentifier(
    game: GameAPITypes.Game | GameAPITypes.GameInfo | CalendarAPITypes.CalendarGame,
    race: GameAPITypes.GameRace,
) {
    const gameType = getGameType(game);
    const gameDef = gameDefs[gameType];

    return gameDef.raceName && gameDef.raceName(game as GameAPITypes.Game, race);
}

export function getPrenumerationName(gameType: GameType | typeof TOP7_PRO): string {
    const gameDef = gameDefs[gameType];
    return gameDef.prenumerationName || gameDef.name;
}

export function getShortName(game: GameAPITypes.Game | GameAPITypes.GameInfo): string {
    return getShortNameFromGameType(getGameType(game));
}

export function getScratchedStartIdsInRace(starts: Array<GameAPITypes.Start>) {
    return starts.filter((start) => start.scratched).map((start) => start.id);
}

export function getStartsForRaceIndex(
    game: GameAPITypes.Game | null | undefined,
    raceIndex: number,
): Array<GameAPITypes.Start> {
    if (!game) return [];
    if (isEmpty(game.races)) return [];
    if (!game.races[raceIndex]) return [];
    return game.races[raceIndex].starts || [];
}

export const getDivisionNumberFromPath = (
    path?: Array<string>,
): number | null | undefined => {
    if (!path || path.length < 4) return null;

    const division = path.indexOf("avd");
    if (division === -1) return null;

    const divisionNo = path[division + 1];

    return parseInt(divisionNo, 10);
};

export function getDivisionNumber(game: GameAPITypes.Game, raceId: any) {
    // Two checks in findIndex to handle both calendarGames and regular games.
    const index = findIndex((race) => {
        if (race.id) return race.id === raceId;
        return race === raceId;
    }, game.races);
    if (index === -1) return null;

    return index + 1;
}

export const getPoolsResults = (
    pools: GameAPITypes.Pools | null | undefined,
    gameType: string,
    showAllPools: boolean,
    extractWinners: PoolWinnersExtractor = Internals.extractComboWinners,
): Array<PoolResult> =>
    flow(
        filter((pool: any) => {
            // pool.id is not present in V-games' pools
            const poolType = pool.betType || Internals.getBetTypeFromPoolId(pool.id);
            if (!includes(poolType, ["trio", "tvilling", "komb"])) return false;

            const currentGamesPool = poolType === gameType;
            const showPool = !currentGamesPool || showAllPools;
            return showPool && pool.result;
        }),
        flatMap((pool) => {
            const poolType = pool.betType || Internals.getBetTypeFromPoolId(pool.id);
            const {winners} = pool.result;

            return extractWinners(poolType, winners);
        }),
    )(pools || []);

/**
 * Get the number of correct horses needed to win in a certain gametype.
 * Sorted from highest to lowest.
 */
export const getCorrectsToWinReversed = (gameType: string): Array<number> =>
    flow(get([gameType, "correctsToWin"]), reverse)(gameDefs);

export function getRaceById(
    game: GameAPITypes.Game,
    raceId: string,
): GameAPITypes.GameRace | null | undefined {
    return find((race) => race.id === raceId, game.races);
}

export function getStartById(
    game: GameAPITypes.Game,
    startId: string,
): GameAPITypes.Start | null | undefined {
    const result = parseStartId(startId);
    if (!result) return null;

    const race = getRaceById(game, result.raceId);
    if (!race) return null;

    const {starts} = race;
    return starts && find((start) => start.id === startId, starts);
}

/**
 * @todo consider merging this with `getShortNameFromGameType` and `getMobileNameFromGameType`
 */

export function getMergedPoolsShortName(gameType: GameType | typeof TOP7_PRO): string {
    const gameDef = gameDefs[gameType];
    return gameDef.mergedPoolsShortName || Internals.getMobileNameFromGameType(gameType);
}

/**
 * Returns tracks from game
 * @param game
 * @returns GameInfoTrack[]
 */
export function getTracks(
    game: GameAPITypes.Game | GameAPITypes.GameInfo,
): Array<GameAPITypes.GameInfoTrack> {
    if (Internals.isGameInfo(game)) {
        return game.tracks;
    }
    return flow([map("track"), uniqBy("id"), compact])(getRaces(game));
}

/**
 * Concatenates all track names from game into a single string
 * @param game
 * @param separator
 * @returns string
 */
export function getTracksNames(game: GameAPITypes.Game, separator = ", ") {
    return flow(sortBy("id"), map("name"), uniq)(getTracks(game)).join(separator);
}

/**
 * Returns gameStartTime for regular and harry page games
 * @param game
 * @returns string | undefined
 */

export const getGameStartTime = (game: GameAPITypes.Game) =>
    Internals.isGameWithStartTime(game) ? game.startTime : game.races?.[0]?.startTime;

export const getStartTime = (
    game: GameAPITypes.Game | CalendarAPITypes.CalendarGame | GameAPITypes.GameInfo,
): dayjs.Dayjs => {
    // `game.startTime` only exists for `CalendarGame` and `GameAPITypes.GameInfo`
    if (game.startTime) return dayjs(game.startTime);

    // not `CalendarGame` or `GameAPITypes.GameInfo --> `game.races[]` are objects and not strings
    return dayjs(
        (game.races[0] as GameAPITypes.GameRace | GameAPITypes.GameInfoRace).startTime,
    );
};

export const getMergedPoolName = ({betTypes}: {betTypes: Array<GameType>}) =>
    map((betType) => getNameFromGameType(betType), betTypes).join("+"); // move to app

export const getPoolsForRace = memoize(
    (
        race: GameAPITypes.GameRace,
        gamePools?: GameAPITypes.Pools,
    ): GameAPITypes.RacePools => {
        const pools = gamePools ? {...gamePools, ...race.pools} : {...race.pools};

        // @ts-expect-error
        if (!race.mergedPools) return pools;

        race.mergedPools.forEach(({betTypes}) => {
            const singlePool = find((pool) => includes(pool?.betType, betTypes), pools);
            if (!singlePool) return;

            const mergedLabel = map((betType) => {
                delete pools[betType];
                return getNameFromGameType(betType);
            }, betTypes).join("+");

            // @ts-expect-error
            singlePool.mergedLabel = mergedLabel;
            // @ts-expect-error
            pools[singlePool.betType] = singlePool;
        });
        // @ts-expect-error
        return pools;
    },
);

export function getSport(game: GameAPITypes.Game): GameAPITypes.Sport {
    // @ts-expect-error
    return game.races && head(game.races)?.sport;
}
export function getPayoutsLegend(gameType: GameType): string | null | undefined {
    if (gameType === GameTypes.V4) {
        return "* = Över 200 000 kr / Utdelning på 3 rätt";
    }

    if (gameType === GameTypes.V5) {
        return "* = Över 200 000 kr / Utdelning på 4 rätt";
    }

    if (gameType === GameTypes.dd || gameType === GameTypes.ld) {
        return "";
    }

    if (isVXYGameType(gameType)) {
        return "* = Över 200 000 kr / Jackpot";
    }

    return null;
}

export const getResultValue = (
    game: {
        [key: string]: any;
    },
    columnValue: any,
) => {
    if (!columnValue) return "*";
    if (columnValue.jackpot) return t`Jackpot`;
    if (columnValue.payoutOnLessLegs) return t`Utd. ${game.races.length - 1} rätt`;

    return formatCurrency(columnValue.amount, {
        hideDecimals: true,
        hideCurrency: true,
    });
};

const getTop7Winners = (raceResult: Top7RaceResult): Array<number> =>
    flow([
        times(identity),
        flatMap((index: number) => raceResult.winners[index + 1]),
        compact,
    ])(7);

const getDivisionGameWinners = (raceResult: DivisionGameRaceResult): Array<number> =>
    raceResult.winners;

const getDubbelWinners = (raceResult: DubbelRaceResult): Array<number> =>
    flow([
        omit(["@type"]),
        flatMap((startNumbers: any) => {
            if (startNumbers[0] === startNumbers[1]) {
                return startNumbers[0];
            }

            return startNumbers;
        }),
        uniq,
    ])(raceResult);

const getComboPoolWinners = (
    raceResult: ComboPoolRaceResult,
    starts?: Array<GameAPITypes.Start> | Array<GameAPITypes.Start>,
): Array<number> => {
    const winners = flow([flatMap("combination"), uniq])(raceResult.winners);

    if (!starts || !starts.length) return winners;

    const allStartsHavePlace = every(
        (start) => start.result && start.result.place !== undefined,
        starts,
    );

    if (!allStartsHavePlace) return winners;

    return flow([
        // @ts-expect-error
        filter((start) => includes(start.number, winners)),
        // @ts-expect-error
        sortBy((s) => s.result.place),
        map("number"),
    ])(starts);
};

const getPlatsWinners = (raceResult: PlatsRaceResult): Array<number> =>
    flow([flatten, compact, map("number")])([
        raceResult.winners.first,
        raceResult.winners.second,
        raceResult.winners.third,
    ]);

const getVinnareWinners = (
    raceResult: VinnareRaceResult | null | undefined,
): Array<number> | null | undefined => {
    if (!raceResult) return undefined;

    return raceResult.winners.map((winner) => winner.number);
};

const getVPWinners = (raceResult: VPRaceResult): Array<number> => {
    const vinnareWinners = getVinnareWinners(raceResult.vinnare) || [];

    const platsWinners = raceResult.plats
        ? getPlatsWinners({
              winners: {
                  first: raceResult.plats.winners.first,
                  second: raceResult.plats.winners.second,
                  third: raceResult.plats.winners.third,
              },
          })
        : [];

    // No point being given the first-place winners twice!
    return uniq(vinnareWinners.concat(platsWinners));
};

export const getWinners = (
    raceResult: RaceResult,
    starts: Array<GameAPITypes.Start>,
    gameType: GameType,
): Array<number> | null | undefined => {
    switch (gameType) {
        case "V75":
        case "GS75":
        case "V86":
        case "V64":
        case "V65":
        case "V5":
        case "V4":
        case "V3":
        case "raket": {
            const divisionRaceResult = raceResult as any as DivisionGameRaceResult;
            return getDivisionGameWinners(divisionRaceResult);
        }
        case "ld":
        case "dd": {
            const dubbelRaceResult = raceResult as any as DubbelRaceResult;
            return getDubbelWinners(dubbelRaceResult);
        }
        case "trio":
        case "komb":
        case "tvilling": {
            const comboPoolRaceResult = raceResult as any as ComboPoolRaceResult;
            return getComboPoolWinners(comboPoolRaceResult, starts);
        }
        case "top7": {
            const top7RaceResult = raceResult as any as Top7RaceResult;
            return getTop7Winners(top7RaceResult);
        }
        case "plats": {
            const platsRaceResult = raceResult as any as PlatsRaceResult;
            return getPlatsWinners(platsRaceResult);
        }
        case "vinnare": {
            const vinnareRaceResult = raceResult as any as VinnareRaceResult;
            return getVinnareWinners(vinnareRaceResult);
        }
        case "vp": {
            const vpRaceResult = raceResult as any as VPRaceResult;
            return getVPWinners(vpRaceResult);
        }
        default:
            return null;
    }
};

const getVPRaceResult = (racePools: GameAPITypes.RacePools): VPRaceResult => {
    const vinnarePool = racePools.vinnare;
    const vinnareResult = vinnarePool
        ? vinnarePool.result
        : {
              winners: [],
          };

    const platsPool = racePools.plats;
    const platsResult = platsPool
        ? platsPool.result
        : {
              winners: {
                  first: [],
                  second: [],
                  third: [],
              },
          };

    return {
        vinnare: vinnareResult,
        plats: platsResult,
    };
};

export const getRaceResult = (
    gameType: GameType,
    racePools: GameAPITypes.RacePools | null | undefined,
): RaceResult | null | undefined => {
    if (!racePools) return null;
    if (gameType === "vp") return getVPRaceResult(racePools);

    const gamesRacePool = racePools[gameType];
    // @ts-expect-error
    return gamesRacePool ? gamesRacePool.result : null;
};

export const getLegNumber = (game: GameAPITypes.Game, raceId: string): number =>
    game.races.findIndex((race) => race.id === raceId) + 1;
