import {size} from "lodash";
import {deprecated_formatCurrency} from "@atg-shared/currency";
import {formatNumber, toNonBreakingString} from "@atg/utils/strings";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type SharedBetShareInfo, getMyShareCost} from "@atg-horse/shared-bet";
// eslint-disable-next-line @nx/enforce-module-boundaries
import systems from "atg-horse-game/domain/raketsystems";
import {calculateV3Shares} from "../../domain/bet";

const getSelected = (bet: {
    [key: string]: any;
}): Array<{
    [key: string]: any;
}> => bet.races.map((leg: any) => size(leg.bets));

export const formatTotal = (amount: number): string =>
    `Totalt: ${toNonBreakingString(deprecated_formatCurrency(amount))}.`;

// in order to ignore shares when we have shareInfo
export const getShares = (bet: {[key: string]: any}): number => {
    if (bet.shareInfo) {
        return 1;
    }

    if (!bet.shares) {
        return 1;
    }

    return bet.shares;
};

export const formatMyTotal = (totalCost: number, shareInfo: SharedBetShareInfo): string =>
    `Min kostnad: ${deprecated_formatCurrency(totalCost)} x ${shareInfo.ownedNrShares}/${
        shareInfo.totalNrShares
    } = ${deprecated_formatCurrency(
        shareInfo.myCost ||
            getMyShareCost(totalCost, shareInfo.totalNrShares, shareInfo.ownedNrShares),
    )}`;

export const costCalculation = (
    factors: Array<string | number>,
    shares: number,
    total: number,
): string => {
    let finalFactors = factors;

    if (shares > 1) {
        // shares > 1 when vinstbevakat andelsspel via ombud
        finalFactors = [...factors, `1/${shares}`];
    }

    return toNonBreakingString(
        `${finalFactors.join(" x ")} = ${deprecated_formatCurrency(total)}.`,
    );
};

const divisionGame = (bet: {[key: string]: any}): Array<string | typeof undefined> => {
    const selected = getSelected(bet);
    const line1 = `${selected.join("x")} = ${bet.combinations} rader.`;

    const {boostInfo, shopShareInfo} = bet;
    const costWithoutAddOns = boostInfo ? bet.cost - boostInfo.cost : bet.cost;
    const betShares = getShares(bet);
    const betSystems = bet.systems || 1;

    let calc;
    if (shopShareInfo) {
        const {soldShares, sharesPerSystem, totalCost} = shopShareInfo;
        const formattedSystems =
            soldShares > sharesPerSystem ? `${soldShares}/${sharesPerSystem}` : "1";

        calc = costCalculation(
            [bet.combinations, formattedSystems, deprecated_formatCurrency(bet.stake)],
            betShares,
            totalCost,
        );
    } else {
        calc = costCalculation(
            [bet.combinations, betSystems, deprecated_formatCurrency(bet.stake)],
            betShares,
            costWithoutAddOns,
        );
    }

    let addOnSpec;
    if (boostInfo) {
        const boostFactors = [];

        if (betSystems > 1) {
            boostFactors.push(betSystems);
        }

        boostFactors.push(
            toNonBreakingString(
                deprecated_formatCurrency((betShares * boostInfo.cost) / betSystems),
            ),
        );

        if (betShares > 1) {
            boostFactors.push(`1/${betShares}`);
        }

        if (boostFactors.length > 1) {
            addOnSpec = `BOOST: ${boostFactors.join(" x ")} = ${toNonBreakingString(
                deprecated_formatCurrency(boostInfo.cost),
            )}`;
        } else {
            addOnSpec = `BOOST: ${toNonBreakingString(
                deprecated_formatCurrency(boostInfo.cost),
            )}`;
        }
    }

    if (bet.fee) {
        const calcWithFee = `${calc} Avgift ${toNonBreakingString(
            deprecated_formatCurrency(bet.fee),
        )}.`;
        const totalCost = bet.cost + bet.fee;
        const lines = [line1, calcWithFee, addOnSpec, formatTotal(totalCost)];

        return bet.shareInfo
            ? [...lines, formatMyTotal(totalCost, bet.shareInfo)]
            : lines;
    }

    if (shopShareInfo) {
        const provision = `Provision: ${deprecated_formatCurrency(
            shopShareInfo.betCommissionAmount,
        )}`;

        const totalWithProvision = `Totalt: ${deprecated_formatCurrency(
            shopShareInfo.totalCost + shopShareInfo.betCommissionAmount,
        )}`;

        return [line1, calc, provision, totalWithProvision];
    }

    const lines = [line1, calc, addOnSpec, formatTotal(bet.cost)];

    return bet.shareInfo ? [...lines, formatMyTotal(bet.cost, bet.shareInfo)] : lines;
};

const komb = (bet: {[key: string]: any}): Array<string> => {
    const betShares = getShares(bet);

    let calc;
    if (
        bet.shopShareInfo &&
        bet.shopShareInfo.soldShares > bet.shopShareInfo.sharesPerSystem
    ) {
        const {soldShares, sharesPerSystem} = bet.shopShareInfo;
        const formattedSystems = `${soldShares}/${sharesPerSystem}`;

        calc = costCalculation(
            [bet.combinations, formattedSystems, deprecated_formatCurrency(bet.stake)],
            betShares,
            bet.cost,
        );
    } else {
        calc = costCalculation(
            [bet.combinations, deprecated_formatCurrency(bet.stake)],
            betShares,
            bet.cost,
        );
    }

    const isTop7ShopShareGame =
        bet.game.type === "top7" && bet.channel === "Butiksandel" && bet.shopShareInfo;
    const betFee = isTop7ShopShareGame ? bet.shopShareInfo.betCommissionAmount : bet.fee;

    if (betFee) {
        const calcWithFee = `${calc} Avgift ${toNonBreakingString(
            deprecated_formatCurrency(bet.fee),
        )}.`;
        const totalCost = bet.cost + betFee;

        if (bet.shopShareInfo) {
            const {betCommissionAmount} = bet.shopShareInfo;
            const provision = `Provision: ${deprecated_formatCurrency(
                betCommissionAmount,
            )}`;
            return [calc, provision, formatTotal(totalCost)];
        }

        const lines = [calcWithFee, formatTotal(totalCost)];

        return bet.shareInfo
            ? [...lines, formatMyTotal(totalCost, bet.shareInfo)]
            : lines;
    }

    if (bet.shopShareInfo) {
        const {betCommissionAmount} = bet.shopShareInfo;
        const provision = `Provision: ${deprecated_formatCurrency(betCommissionAmount)}`;
        return [calc, provision, formatTotal(bet.cost)];
    }

    const lines = [calc, formatTotal(bet.cost)];

    return bet.shareInfo ? [...lines, formatMyTotal(bet.cost, bet.shareInfo)] : lines;
};

const trioflex = (bet: {[key: string]: any}): Array<string> => {
    // FlexValue is delivered in "per mil"
    const percentage = bet.flexValue / 10;

    const info = `${bet.combinations} kombinationer, ${formatNumber(percentage, {
        fractionDigits: 1,
    })}%`;
    const lines = [info, formatTotal(bet.cost)];
    return bet.shareInfo ? [...lines, formatMyTotal(bet.cost, bet.shareInfo)] : lines;
};

const oneOrTwoRacesGame = (
    bet: {
        [key: string]: any;
    },
    multiplier: number,
): Array<string> => {
    const rows = bet.combinations;
    const stake = toNonBreakingString(deprecated_formatCurrency(bet.stake));
    const betShares = getShares(bet);

    const factors = multiplier > 1 ? [rows, multiplier, stake] : [rows, stake];
    const calc = costCalculation(factors, betShares, bet.cost);

    if (bet.fee) {
        const calcWithFee = `${calc} Avgift ${toNonBreakingString(
            deprecated_formatCurrency(bet.fee),
        )}.`;

        const totalCost = bet.cost + bet.fee;
        const lines = [calcWithFee, formatTotal(totalCost)];

        return bet.shareInfo
            ? [...lines, formatMyTotal(totalCost, bet.shareInfo)]
            : lines;
    }

    const lines = [calc, formatTotal(bet.cost)];

    return bet.shareInfo ? [...lines, formatMyTotal(bet.cost, bet.shareInfo)] : lines;
};

const dubbelGame = (bet: {[key: string]: any}): Array<string> =>
    oneOrTwoRacesGame(bet, 1);

const singleGame = (bet: {[key: string]: any}): Array<string> =>
    oneOrTwoRacesGame(bet, 1);

const vpGame = (bet: {[key: string]: any}): Array<string> => oneOrTwoRacesGame(bet, 2);

const v3Game = (bet: {[key: string]: any}): Array<string | typeof undefined> => {
    if (bet.verbalV3Info || bet.v3LegNumber) {
        // in case of a terminal bet, response from old API will have verbalV3Info, and from new horse bet history - v3LegNumber and will need to calculate amount of shares
        const shares =
            bet.verbalV3Info?.totalNrShares || calculateV3Shares(bet.selections[0]);

        const total = deprecated_formatCurrency(bet.stake * shares);
        const firstRow = `${shares} andel${
            shares > 1 ? "ar" : ""
        } x ${deprecated_formatCurrency(
            bet.stake,
        )} = ${total}. Avgift ${toNonBreakingString(
            deprecated_formatCurrency(bet.fee),
        )}.`;
        const secondRow = `Totalt: ${total}.`;

        return [firstRow, secondRow];
    }
    return divisionGame(bet);
};

const raketGame = (bet: {[key: string]: any}): Array<string> => {
    const betShares = getShares(bet);
    const calc = costCalculation(
        [bet.combinations, deprecated_formatCurrency(bet.stake)],
        betShares,
        bet.cost,
    );
    return [calc, formatTotal(bet.cost)];
};

const raketGameSystem = (bet: {[key: string]: any}): Array<string> | null => {
    if (!bet.system) return null;
    // @ts-expect-error
    return systems[bet.system] && [`System: ${systems[bet.system].label}`];
};

export {
    komb,
    trioflex,
    divisionGame,
    dubbelGame,
    singleGame,
    vpGame,
    raketGame,
    raketGameSystem,
    v3Game,
};
