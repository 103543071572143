import log, {serializeError} from "@atg-shared/log";
import type {GameTypeWithoutLegacy, GameType} from "@atg-horse-shared/game-types";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {CouponGame} from "@atg-horse-shared/coupon-types";

/**
 * This will be refactored after V3 Varenne is in production.
 * Also, check:
 * libs/horse-shared/game-types/src/gameTypesLegacy.ts
 * libs/mobileapp/horse/columns/src/columnRenderers.tsx
 */

export const getGameType = <T extends Game>(game: T): GameType =>
    game.type === "V3" && !game.newBettingSystem ? "V3Legacy" : game.type;

export const getGameTypefromCouponGame = (
    game: CouponGame<GameTypeWithoutLegacy>,
): GameType => {
    try {
        // eslint-disable-next-line no-underscore-dangle
        const gameFromStore = window._horseStore?.getState().games.gameData[game.id].game;

        return gameFromStore.type === "V3" && !gameFromStore.newBettingSystem
            ? "V3Legacy"
            : gameFromStore.type;
    } catch (error: unknown) {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        log.error("V3Utils: Game not found in store", {
            error: serializeError({error}),
        });

        return game.type;
    }
};
