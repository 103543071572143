export const DepositActionConstants = {
    ABORT_TRUSTLY_DEPOSIT: "deposit/ABORT_TRUSTLY_DEPOSIT",

    CANCEL_CARD_DEPOSIT_IN_IFRAME: "deposit/CANCEL_CARD_DEPOSIT_IN_IFRAME",
    CANCEL_SWISH_DIRECT: "deposit/CANCEL_SWISH_DIRECT",

    CLEAR_IFRAME_DEPOSIT_STATE: "deposit/CLEAR_IFRAME_DEPOSIT_STATE",
    CLEAR_STATUS_MESSAGES: "deposit/CLEAR_STATUS_MESSAGES",

    DELETE_BANK_CARD: "deposit/DELETE_BANK_CARD",
    DELETE_BANK_CARD_SUCCESS: "deposit/DELETE_BANK_CARD_SUCCESS",
    DELETE_BANK_CARD_FAILURE: "deposit/DELETE_BANK_CARD_FAILURE",

    DEPOSIT_MONEY: "deposit/DEPOSIT_MONEY",
    DEPOSIT_START: "deposit/DEPOSIT_START",
    DEPOSIT_SWISH_DIRECT_START: "deposit/DEPOSIT_SWISH_DIRECT_START",
    DEPOSIT_FINALIZE: "deposit/DEPOSIT_FINALIZE",
    DEPOSIT_FINALIZE_IFRAME: "deposit/DEPOSIT_FINALIZE_IFRAME",
    DEPOSIT_SUCCESS: "deposit/DEPOSIT_SUCCESS",
    DEPOSIT_FAILURE: "deposit/DEPOSIT_FAILURE",
    DEPOSIT_PENDING: "deposit/DEPOSIT_PENDING",
    DEPOSIT_BLOCKED: "deposit/DEPOSIT_BLOCKED",
    DEPOSIT_CLOSED: "deposit/DEPOSIT_CLOSED",

    DEPOSIT_FLOW_STARTED: "deposit/DEPOSIT_FLOW_STARTED",
    DEPOSIT_FLOW_FINISHED: "deposit/DEPOSIT_FLOW_FINISHED",

    FETCH_OPTIONS: "deposit/FETCH_OPTIONS",
    FETCH_OPTIONS_SUCCESS: "deposit/FETCH_OPTIONS_SUCCESS",
    FETCH_OPTIONS_FAILURE: "deposit/FETCH_OPTIONS_FAILURE",

    LOAD_DEPOSIT_STATE: "deposit/LOAD_DEPOSIT_STATE",

    POPUP_BLOCKED: "deposit/POPUP_BLOCKED",

    RESET_DEPOSIT_IS_PENDING: "deposit/RESET_DEPOSIT_IS_PENDING",

    SET_SWISH_DIRECT_ID: "deposit/SET_SWISH_DIRECT_ID",
    SET_DEPOSIT_AMOUNT: "deposit/SET_DEPOSIT_AMOUNT",
    SET_IFRAME_DEPOSIT_STATE: "deposit/DEPOSIT_REDIRECT_URL",
    SET_SELECTED_OPTION: "deposit/SET_SELECTED_OPTION",
    SET_SUCCESSFULL_DEPOSIT_METHOD: "deposit/SET_SUCCESSFULL_DEPOSIT_METHOD",
    SET_SWISH_DIRECT_PHONE_NUMBER: "deposit/SET_SWISH_DIRECT_PHONE_NUMBER",
    SET_SWISH_DIRECT_STEP: "deposit/SET_SWISH_DIRECT_STEP",

    START_DEPOSIT_FLOW: "deposit/START_DEPOSIT_FLOW",
    START_DEPOSIT_FLOW_IN_MODAL: "deposit/START_DEPOSIT_FLOW_IN_MODAL",

    SWISH_DIRECT_STEP: "deposit/SWISH_DIRECT_STEP",
    SWISH_DIRECT_PHONE_NUMBER: "deposit/SWISH_DIRECT_PHONE_NUMBER",

    UPDATE_SWISH: "deposit/UPDATE_SWISH",
    UPDATE_SWISH_SUCCESS: "deposit/UPDATE_SWISH_SUCCESS",
    UPDATE_SWISH_FAILURE: "deposit/UPDATE_SWISH_FAILURE",
} as const;
