import {call, put, select, delay} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import log, {serializeError} from "@atg-shared/log";
import {DepositMessages, DepositMethods} from "@atg-payment-shared/deposit-utils";
import type {Message} from "atg-ui-messages";
import * as DepositSelectors from "../../selectors/selectors";
import * as DepositApi from "../../api/api";
import * as DepositActions from "../../actions/actions";
import {
    getStoredUserDepositPreference,
    deleteStoredUserDepositPreference,
    type DepositResponseError,
} from "../helpers/sagaHelpers";

export function* cancelCardDepositInIframe(): SagaIterator {
    try {
        const orderId = yield select(DepositSelectors.orderIdForDepositIframe);

        yield call(DepositApi.cancelInitiatedDepositInIframe, orderId);
        yield put(DepositActions.clearIframeDepositState());
        yield put(DepositActions.resetDepositIspending());
    } catch (e: unknown) {
        const err = e as DepositResponseError;
        log.error("cancelCardDepositInIframe: ", {error: serializeError(err)});
    }
}

// Options flows
export function* deleteBankCardFlow(): SagaIterator {
    try {
        yield call(DepositApi.deleteStoredBankCard);

        const userName = yield select(UserSelectors.getUsername);
        const storedUserDepositPreference = yield call(
            getStoredUserDepositPreference,
            userName,
        );
        if (
            storedUserDepositPreference &&
            storedUserDepositPreference.id === DepositMethods.existingCard
        )
            yield call(deleteStoredUserDepositPreference, userName);
    } catch (error: unknown) {
        const err = error as DepositResponseError;

        let message: Message;

        switch (err.response?.meta?.statusCode) {
            case 0:
                message = DepositMessages().CONNECTION_LOST;
                break;
            case 400:
            case 401:
                message = DepositMessages().WRONG_PASSWORD;
                break;
            default:
                log.error(
                    `Removing user's credit card failed with status ${err.response?.meta?.statusCode}`,
                    {
                        error: serializeError(error),
                    },
                );
                message = DepositMessages().CARD_REMOVE_FAIL;
        }

        yield put(DepositActions.removeBankCardFailure(message));
        return;
    }

    yield put(
        DepositActions.deleteBankCardSuccess(DepositMessages().CARD_REMOVE_SUCCESS),
    );
    yield delay(5000);
    yield put(DepositActions.clearStatusMessages());
}
