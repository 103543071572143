import {
    all,
    call,
    cancel,
    delay,
    fork,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
    spawn,
    race,
} from "redux-saga/effects";
import type {SagaIterator, Task} from "redux-saga";
import dayjs from "dayjs";
import {omit, omitBy, flow, keys, set, updateWith, isUndefined} from "lodash";
import {isApp} from "@atg-shared/system";
import log, {serializeError} from "@atg-shared/log";
import * as Time from "@atg-shared/server-time";
import atgFetch from "@atg-shared/fetch";
import {type AtgRequestError} from "@atg-shared/fetch-types";
import {COUPON_SERVICE_URL, USER_COUPON_SERVICE_URL} from "@atg-shared/service-url";
import {fetchAuthorized, AuthenticationCancelledError} from "@atg-shared/auth";
import {
    CouponTypes,
    type CouponType,
    type Coupon,
    type HarryCouponTypes,
} from "@atg-horse-shared/coupon-types";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import {AlertActions} from "@atg-global-shared/alerts-data-access";
import {trackHorseEvent} from "@atg-horse-shared/analytics";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {LOGIN_FINISHED} from "@atg-global-shared/user/userActionTypes";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {ReducedBetsSelectors} from "@atg-horse/reduced-bets";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PurchaseActions from "@atg-horse-shared/purchase/src/domain/purchaseActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PurchaseSelectors from "@atg-horse-shared/purchase/src/domain/purchaseSelectors";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    PLAYED_BET,
    STARTED_VARENNE_FLOW,
    type StartedVarenneBetAction,
    type PlayedBetAction,
    isReducedCouponTypes,
} from "@atg-horse/horse-bet";
import {runNativeABTest} from "@atg-global-shared/personalization/domain/personalizationUtil";
import {logoutUser} from "@atg-global-shared/user/userActions";
import {DIRECT_COUPON_INTEGRATION} from "@atg-global-shared/personalization/domain/nativePersonalizationConstants";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getGameById} from "atg-horse-game/domain/gameSelectors";
import {frameAction} from "atg-store-addons";
import * as CouponUtils from "./coupon";
import * as CouponActions from "./couponActions";
import * as CouponSelectors from "./couponSelectors";
import * as CouponLocalApi from "./couponLocalApi";

const COUPON_SYNC_DELAY = 5000;
const COUPON_SYNC_TIMEOUT = 10000;

/*
 * timestamps with cids as keys. will contain the latest timestamp for a coupon with a given cid
 * server keeps a record of the last timestamp. So frontend needs to send latest timestamp to ensure the sync is happening without a conflict
 */
const timestamps: Record<string, unknown> = {};

/**
 * This function does just a network request
 * error handling should be done in the requesting function, since we may need to handle errors differently
 * for example, when we share a coupon we do not want to fire COUPON_SYNCED actions
 */
function* requestCouponSync(
    coupon: Coupon,
): SagaIterator<{id: string; modified: string} | null | undefined> {
    const isReduced = isReducedCouponTypes(coupon.type);

    const {variation} = yield call(
        runNativeABTest,
        DIRECT_COUPON_INTEGRATION,
        // eslint-disable-next-line no-underscore-dangle
        window._horseStore,
    );

    const baseUrl = getCouponUrl(variation, isReduced);

    // post for new coupons, put for synced ones
    const {method, url} = CouponUtils.isNew(coupon)
        ? {
              method: "POST",
              url: baseUrl,
          }
        : {
              method: "PUT",
              url: `${baseUrl}/${coupon.id}`,
          };

    const reductionTerms = yield select(
        ReducedBetsSelectors.getReductionTerms,
        coupon.cid,
    );

    if (reductionTerms?.reductionMetadata) {
        const {type, races} = yield select(getGameById, coupon.game.id);
        const raceBetPercentageValues = CouponUtils.addRaceBetPercentageValue(
            type,
            races,
        );
        set(reductionTerms, `expectedOutcome.races`, raceBetPercentageValues);
    }

    // get a timestamp
    const modifiedTimestamp = timestamps[coupon.cid];
    // will start sync, so it is not delayed anymore
    yield put(CouponActions.startSyncingCoupon(coupon.cid));

    log.debug("couponSyncSaga:requestCouponSync for coupon ", {
        coupon,
        modifiedTimestamp,
    });

    const requestOptions = {
        method,
        body: JSON.stringify(
            CouponUtils.joinCouponData(coupon, modifiedTimestamp, reductionTerms),
        ),
    };

    if (!isReduced && reductionTerms && !isApp) {
        // TODO: this causes lots of logs. While this is obviosly an issue, we should not log it atm. Fix a but instead - https://jira-atg.riada.cloud/browse/TBET-923
        // log.error("couponSyncSaga:requestCouponSync REDUCED coupon typed as PRIVATE", {
        //     cid: coupon.cid,
        //     couponId: coupon.id,
        // });
    }

    let response;
    if (isApp) {
        // The App does not want to be suddenly booted out to a web-browser to login.
        response = yield call(fetchAuthorized, url, requestOptions, {
            memberFlowEnabled: false,
        });
    } else {
        const memberFlowOptions = yield select(AuthSelectors.getMemberFlowOptions);

        response = yield call(
            fetchAuthorized,
            url,
            requestOptions,
            {
                memberFlowOptions: {
                    ...memberFlowOptions,
                    loginMessage:
                        "Du har blivit utloggad. Logga in för att få tillgång till samtliga funktioner.",
                },
            },
            atgFetch,
        );
    }

    return response.data;
}

/**
 * Await for the response for the coupon sync
 * @param {string} cid - cid (coupon "client ID") for the coupon we are awaiting
 */
function* awaitSyncForCid(cid: string): SagaIterator<Coupon | null | undefined> {
    while (true) {
        log.debug("couponSyncSaga:awaitSyncForCid will wait", {cid});
        const syncAction = yield take(CouponActions.COUPON_SYNCED);
        const syncedCoupon = syncAction.payload.coupon;

        if (!syncedCoupon) return null;

        if (syncedCoupon.cid === cid) {
            log.debug("couponSyncSaga:awaitSyncForCid received syncedCoupon", {cid});
            return syncedCoupon;
        }

        log.debug(
            "couponSyncSaga:awaitSyncForCid received syncedCoupon with a different cid. Continue waiting...",
            {syncAction},
        );
    }
}

function* onSave(
    coupon: Coupon,
    {id, modified}: {id?: string; modified: string},
): SagaIterator<Coupon | null> {
    const {cid} = coupon;
    const hasCoupon = yield select(CouponSelectors.hasCoupon, cid);
    if (!hasCoupon) {
        log.debug("coupon was removed. will remove the timestamp. cid: ", cid);
        // coupon was removed before we received the response. only need to remove a timestamp and clean up store
        omit(timestamps, [cid]);
        yield put(CouponActions.syncedCouponMissing(cid));
        return null;
    }

    log.debug(
        "coupon is in a store. update the timestamp and dispatch couponSynced. cid: ",
        cid,
    );

    const reductionTerms = yield select(ReducedBetsSelectors.getReductionTerms, cid);

    // save a new timestamp
    timestamps[cid] = modified;

    // update id and modified attribute to avoid confusion, since it would be "old" otherwise
    const updatedCoupon = CouponUtils.updateCoupon(coupon, {id, modified});

    yield put(CouponActions.couponSynced(cid, updatedCoupon, reductionTerms));

    return updatedCoupon;
}

function* retry(
    coupon: Coupon,
    isRetrying: boolean,
    origin: any,
): SagaIterator<Coupon | null | undefined> {
    if (isRetrying) {
        yield put(
            // @ts-expect-error string should be Record
            CouponActions.syncCouponError(coupon.cid, "couponSyncSaga: retry failed"),
        );
        log.error("couponSyncSaga: failed to sync on retry", {
            couponId: coupon.id,
            teamId: coupon.teamId,
            type: coupon.type,
            origin,
        });

        return null;
    }

    // do a retry
    // @ts-expect-error
    return yield* persistCouponRemotely(coupon, origin, true);
}

export function* persistCouponRemotely(
    couponToSync: Coupon,
    origin: string,
    isRetrying = false,
): SagaIterator<Coupon | null | undefined> {
    let coupon = couponToSync;
    const {cid} = couponToSync;
    log.debug("couponSyncSaga:persistCouponRemotely", {coupon});

    if (coupon.teamId && !coupon.id) {
        log.warn(
            "couponSyncSaga:persistCouponRemotely. Wrong logic: coupon has teamId, but no id",
            {cid, teamId: coupon.teamId, type: coupon.type, origin},
        );
    }

    const isSyncing = yield select(CouponSelectors.isSyncing(cid));

    // await if there is another sync ongoing.
    // if it is a retry, do not await to avoid being stuck.
    if (isSyncing && !isRetrying) {
        log.debug("couponSyncSaga:persistCouponRemotely await coupon sync for ", {
            cid,
        });

        // use timeout to ensure we are not accidentally stuck
        const {syncedCoupon} = yield race({
            syncedCoupon: call(awaitSyncForCid, cid),
            timeout: delay(COUPON_SYNC_TIMEOUT),
        });

        if (syncedCoupon) {
            log.debug(
                "couponSyncSaga:persistCouponRemotely received awaited sync response for ",
                {cid},
            );
            if (!coupon.id) {
                log.debug(
                    "couponSyncSaga:persistCouponRemotely assign id retrieved from sync: ",
                    {id: syncedCoupon.id},
                );
                coupon = {...coupon, id: syncedCoupon.id};
            }
        } else {
            log.debug(
                "couponSyncSaga:persistCouponRemotely did not receive awaited response for",
                {cid},
            );
        }
    }

    log.debug("couponSyncSaga:persistCouponRemotely will sync coupon to the server: ", {
        coupon,
    });

    try {
        // do an actual server request
        // @ts-expect-error
        const syncResponse = yield* requestCouponSync(coupon);

        log.debug(
            "couponSyncSaga:persistCouponRemotely successfully saved coupon to the server: ",
            {cid: coupon.cid, syncResponse},
        );

        // @ts-expect-error
        return yield* onSave(coupon, syncResponse);
    } catch (err: unknown) {
        const error = err as AtgRequestError;
        const code = error?.response?.meta?.code;

        if (error instanceof AuthenticationCancelledError) {
            // logout user
            yield put(frameAction(logoutUser()));
            log.warn(
                "couponSyncSaga:persistCouponRemotely:AuthenticationCancelledError. Logged out.",
            );

            return null;
        }

        if (code === 404) {
            // coupon not found on PUT. Remove id and retry (will POST).
            log.warn(
                "couponSyncSaga:persistCouponRemotely:Error404. Remove id and retry.",
            );
            const couponWithoutId = omit(coupon, ["id"]);
            const couponTypesWithTeams: Array<Partial<CouponType | HarryCouponTypes>> = [
                CouponTypes.SHOP_SHARED,
                CouponTypes.PRIVATE_TEAM,
                CouponTypes.SHARED,
            ];

            if (coupon.teamId || couponTypesWithTeams.includes(coupon.type)) {
                yield put(
                    AlertActions.gameInfoAlert({
                        alertId: coupon.id,
                        gameId: coupon.game.id,
                        message:
                            "Din kupong kan inte hittas. Kontrollera påbörjade kuponger.",
                        title: "",
                    }),
                );
                log.error(
                    "couponSyncSaga:persistCouponRemotely:Error404. Showed not found error for non-private coupon",
                    {
                        couponId: coupon.id,
                        teamId: coupon.teamId,
                        type: coupon.type,
                        origin,
                    },
                );

                return null;
            }

            // @ts-expect-error
            return yield* retry(couponWithoutId, isRetrying, origin);
        }

        if (code === 409) {
            if (coupon.type === CouponTypes.PRIVATE) {
                // special handling for private coupons - create a new coupon from local version
                // for that just remove "id", it will then create a new coupon via POST request.
                log.warn(
                    "couponSyncSaga:persistCouponRemotely:Error409: will create a new version of a coupon",
                );
                const couponWithoutId = omit(coupon, ["id"]);

                if (coupon.teamId) {
                    yield put(
                        AlertActions.gameInfoAlert({
                            alertId: coupon.id,
                            gameId: coupon.game.id,
                            message:
                                "Kunde inte spara kupongen. Försök att ladda om sidan.",
                            title: "",
                        }),
                    );
                    log.error(
                        "couponSyncSaga:persistCouponRemotely:Error409. Bad handling: private coupon has teamId",
                        {couponId: coupon.id, teamId: coupon.teamId, origin},
                    );

                    return null;
                }

                // @ts-expect-error
                return yield* retry(couponWithoutId, isRetrying, origin);
            }

            // for other tickets than private, we will fetch the latest copy from the server.
            // reason for that is that e.g. "andelsspel" should only have one coupon.
            // there are two alternatives in case of conflict:
            // let the local version "win", or let the version on a server "win"
            // we have decided that we let the server version to "win" and therefore reload it.
            const game = yield select(getGameById, coupon.game.id);

            yield put(
                CouponActions.fetchCoupon({
                    cid: coupon.cid, // keep the cid
                    couponId: coupon.id, // will fetch by coupon id
                    forceFetch: true,
                    game,
                }),
            );

            const action = yield take(CouponActions.RECEIVE_COUPON);

            if (!action.error) {
                yield put(
                    AlertActions.gameInfoAlert({
                        alertId: coupon.id,
                        gameId: coupon.game.id,
                        message: "Din kupong har uppdaterats till den senaste versionen",
                        title: "",
                    }),
                );
            } else {
                yield put(
                    AlertActions.gameInfoAlert({
                        alertId: coupon.id,
                        gameId: coupon.game.id,
                        message: "Kupongen kunde inte sparas. Försök att ladda om sidan.",
                        title: "",
                    }),
                );
                log.error(
                    "couponSyncSaga:persistCouponRemotely:Error409. Could not refetch non-private coupon on conflict",
                    {
                        couponId: coupon.id,
                        teamId: coupon.teamId,
                        type: coupon.type,
                        origin,
                    },
                );
            }

            return null;
        }

        if (code === 500) {
            // server down...
            // TODO: check that sync is result of a user action
            yield put(
                AlertActions.gameInfoAlert({
                    alertId: coupon.id,
                    gameId: coupon.game.id,
                    message: "Kupongen kunde inte sparas. Försök igen senare.",
                    title: "",
                }),
            );

            yield put(CouponActions.syncCouponError(coupon.cid, error));
            log.error(
                "couponSyncSaga:persistCouponRemotely: showed alert to user on error 500. ",
                {
                    origin,
                    couponId: coupon.id,
                    teamId: coupon.teamId,
                    type: coupon.type,
                    error: serializeError(error),
                },
            );

            return null;
        }

        if (code === 0) {
            // connection down...
            // TODO: check that sync is result of a user action
            yield put(
                AlertActions.gameInfoAlert({
                    alertId: coupon.id,
                    gameId: coupon.game.id,
                    message:
                        "Ingen internetanslutning. Kontrollera din uppkoppling och försök igen.",
                    title: "",
                }),
            );

            yield put(CouponActions.syncCouponError(coupon.cid, error));
            log.error(
                "couponSyncSaga:persistCouponRemotely: showed alert to user on no network. ",
                {
                    origin,
                    couponId: coupon.id,
                    teamId: coupon.teamId,
                    type: coupon.type,
                    error: serializeError(error),
                },
            );

            return null;
        }

        // in case we did not handle the error (e.g. error code 500):
        yield put(CouponActions.syncCouponError(coupon.cid, error));

        log.error("couponSyncSaga:persistCouponRemotely: unhandled error: ", {
            origin,
            couponId: coupon.id,
            teamId: coupon.teamId,
            type: coupon.type,
            error: serializeError(error),
        });

        if (coupon.teamId && !coupon.id) {
            log.warn(
                "couponSyncSaga:persistCouponRemotely:unhandled error. Coupon has teamId, but not id. Bad state.",
                {cid: coupon.cid, teamId: coupon.teamId, origin},
            );
        }

        return null;
    }
}

function* saveLocally(coupon: Coupon): SagaIterator<Coupon | null | undefined> {
    try {
        log.debug("couponSyncSaga:saveLocally", {coupon});

        const reductionTerms = yield select(
            ReducedBetsSelectors.getReductionTerms,
            coupon.cid,
        );
        // @ts-expect-error startSyncingCoupon and reducer expects a cid
        yield put(CouponActions.startSyncingCoupon(coupon));

        const modifiedTimestamp = timestamps[coupon.cid];

        const syncResponse = yield call(
            // @ts-expect-error
            CouponLocalApi.saveCoupon,
            coupon,
            modifiedTimestamp,
            reductionTerms,
        );
        // @ts-expect-error
        return yield* onSave(coupon, syncResponse);
    } catch (error: unknown) {
        // @ts-expect-error error not typed
        yield put(CouponActions.syncCouponError(coupon.cid, error));
        return null;
    }
}

function getType(type: CouponType, variation: number) {
    if (variation === 1) {
        if (type === CouponTypes.SHARED || type === CouponTypes.PRIVATE_TEAM) {
            return CouponTypes.PRIVATE;
        }
    }
    return type;
}

/**
 * Function used in our main sync saga
 * If logged in, it will spawn persistCouponRemotely so that we can await for the result without cancelling it.
 */
export function* syncCoupon(couponToSync: Coupon, origin: string): SagaIterator<void> {
    log.debug("couponSyncSaga:syncCoupon. For coupon: ", {couponToSync});

    if (!couponToSync) return;

    const {variation} = yield call(
        runNativeABTest,
        DIRECT_COUPON_INTEGRATION,
        // eslint-disable-next-line no-underscore-dangle
        window._horseStore,
    );

    const coupon = couponToSync.readOnly
        ? flow([
              (originalCoupon) => omit(originalCoupon, ["id", "readOnly", "teamId"]),
              // Set SHARED types to PRIVATE since SHARED coupon should become PRIVATE upon save.
              (originalCoupon) =>
                  updateWith(originalCoupon, ["type"], (type) =>
                      getType(type, variation),
                  ),
              (originalCoupon) => omitBy(originalCoupon, isUndefined),
          ])(couponToSync)
        : couponToSync;

    const couponToSave = yield call(CouponUtils.prepareForSave, coupon);

    if (!couponToSave) return;

    const isLoggedIn = yield select(UserSelectors.isLoggedIn);

    const shouldPersistRemotely = isLoggedIn && !coupon.localOnly;

    if (shouldPersistRemotely) {
        log.debug("couponSyncSaga:syncCoupon: will spawn persistCouponRemotely for ", {
            couponToSave,
        });
        yield spawn(persistCouponRemotely, couponToSave, origin);
    } else {
        log.debug("couponSyncSaga:syncCoupon: will saveLocally for ", {couponToSave});
        // @ts-expect-error
        yield* saveLocally(couponToSave);
    }
}

/**
 * In some cases (e.g. creating a shared bet for new coupon) we want to:
 * create a new coupon on the server
 * and fire COUPON_SYNCED actions
 */
export function* createRemoteCouponAndSync(
    coupon: Coupon,
): SagaIterator<Coupon | null | undefined> {
    log.debug("couponSyncSaga:createRemoteCouponAndSync", {coupon});

    if (!coupon) return null;

    const couponToSave = CouponUtils.prepareForSave(omit(coupon, ["id", "readOnly"]));

    if (!couponToSave) return null;

    // do a full-blown persistCouponRemotely that will fire COUPON_SYNCED as a result
    // @ts-expect-error
    return yield* persistCouponRemotely(couponToSave, "createRemoteCouponAndSync");
}

/**
 * Create a shareable coupon:
 * It should have a property readonly
 * It should not have id (so that we get a new id from the server)
 * We do not want to fire COUPON_SYNCED actions on response
 */
export function* createShareableCoupon(
    coupon: Coupon,
): SagaIterator<Coupon | null | undefined> {
    log.debug("couponSyncSaga:createShareableCoupon", {coupon});

    if (!coupon) return null;

    // remove id and add readOnly flag
    const readOnlyCoupon = {
        ...omit(coupon, ["id"]),
        readOnly: true,
        // CouponTypes.REDUCED is already shared when created
        type:
            coupon.type !== CouponTypes.REDUCED
                ? CouponTypes.SHARED
                : CouponTypes.REDUCED,
    };
    const couponToSave = CouponUtils.prepareForSave(readOnlyCoupon);

    if (!couponToSave) return null;

    // do a server request, we are only interested in the coupon
    // @ts-expect-error
    return yield* requestCouponSync(couponToSave);
}

export function* syncLocalCoupon(
    id: string,
    couponData: string,
    liveDate: string,
): SagaIterator<void> {
    let coupon;
    try {
        coupon = yield call(CouponUtils.createCouponFromJSON, JSON.parse(couponData));
    } catch (e: unknown) {
        log.error("couponSyncSaga:syncLocalCoupon: could not parse coupon", {
            id,
            couponData,
            error: serializeError(e),
        });
        return;
    }
    if (coupon.localOnly) {
        const isOldCoupon = dayjs(coupon.game.date).diff(dayjs(liveDate), "days") < -1;
        if (isOldCoupon) {
            yield call(CouponLocalApi.removeCoupon, id);
        }
        return;
    }

    yield call(syncCoupon, coupon, "syncingLocalCoupons");
    yield call(CouponLocalApi.removeCoupon, id);
}

export function* saveAllLocalCoupons(): SagaIterator<void> {
    const coupons = yield call(CouponLocalApi.fetchCoupons);
    const couponLocalIds = keys(coupons);
    if (couponLocalIds.length) {
        // @ts-expect-error 2 different type declarations
        const serverTime = yield call(Time.serverTime);
        const liveDate = serverTime.format("YYYY-MM-DD");

        yield put(CouponActions.startSyncingLocalCoupons(couponLocalIds.length));
        yield all(
            couponLocalIds.map((localId) =>
                call(syncLocalCoupon, localId, coupons[localId], liveDate),
            ),
        );
    }
    yield put(CouponActions.allLocalCouponsSynced());
}

export function* delayedSync(coupon: Coupon, origin: string): SagaIterator<void> {
    log.debug("couponSyncSaga:delayedSync delay", {coupon});
    // mark coupon as delayed
    yield put(CouponActions.couponSyncDelayed(coupon.cid));
    yield delay(COUPON_SYNC_DELAY);
    // @ts-expect-error
    yield* syncCoupon(coupon, origin);
}

export function* syncCouponChangeFlow(
    syncTasks: Map<string, Task>,
    action: CouponActions.CouponChangedAction,
    syncWithoutDelay: boolean,
    origin: string,
): SagaIterator<void> {
    const {cid, coupon} = action.payload;

    log.debug("couponSyncSaga:syncCouponChangeFlow", {action});

    const isLoggedIn = yield select(UserSelectors.isLoggedIn);

    // TODO: consider if we need this functionality with syncTasks.
    // an easy way would be just using takeLatest instead of cancelling tasks manually
    // we would loose ability to cancel tasks per coupon id.
    const {localOnly = false} = coupon;
    const {id} = coupon;
    const taskForCid = syncTasks.get(cid);

    if (id && taskForCid) {
        // I am not 100%, but this is my understanding of the code:
        // for new coupon we did not have an id, so the task was created with cid.
        // now we supposedly have synced and have an id, get rid of the task with cid.
        yield cancel(taskForCid);
        syncTasks.delete(cid);
    }

    const key = id || cid;
    const task = syncTasks.get(key);
    if (task && task.isRunning()) {
        // here we got a task by cid (if coupon is not synced) or id (for synced coupons)
        // since we have a new action for it, cancel it.
        yield cancel(task);
    }

    const doSyncWithoutDelay = !isLoggedIn || localOnly || syncWithoutDelay;

    const taskToPerform = doSyncWithoutDelay ? syncCoupon : delayedSync;

    const newTaskForCid = yield fork(taskToPerform, action.payload.coupon, origin);
    syncTasks.set(key, newTaskForCid);
}

export function* cancelSyncTask(
    id: string,
    syncTasks: Map<string, Task>,
): SagaIterator<void> {
    const syncTask = syncTasks.get(id);
    if (!syncTask) {
        return;
    }

    yield cancel(syncTask);
    yield put(CouponActions.cancelCouponSync(id));
    syncTasks.delete(id);
}
export function* couponPlayFlow(
    syncTasks: Map<string, Task>,
    action: any,
): SagaIterator<void> {
    const {parentCid} = action.payload.coupon || {}; // some flows do not have a coupon
    if (!parentCid) return;

    const betAction = yield take([PLAYED_BET, PurchaseActions.FINISH_PURCHASE_FLOW]);
    if (betAction.type === PurchaseActions.FINISH_PURCHASE_FLOW || betAction.error)
        return; // bet is cancelled/error
    yield call(cancelSyncTask, parentCid, syncTasks);
}

export function* cancelCouponSyncFlow(
    syncTasks: Map<string, Task>,
    action: any,
): SagaIterator<void> {
    if (action.error) return;

    const {couponId} = action.payload;
    if (!couponId) return;

    yield call(cancelSyncTask, couponId, syncTasks);
}

/**
 * Saga cancelling the coupon sync
 */
export function* cancelCouponSync(
    syncTasks: Map<string, Task>,
    action: StartedVarenneBetAction | PlayedBetAction,
): SagaIterator<void> {
    const product = yield select(PurchaseSelectors.getProduct);
    const {cid} = product || {};
    const coupon = yield select(CouponSelectors.getCoupon, cid);
    const {id, parentCid} = coupon || {};

    if (id) {
        // cancel sync for coupon in case it was synced already
        yield call(cancelSyncTask, id, syncTasks);
    }

    if (cid) {
        // cancel sync for coupon in case it was not synced and was not cloned (e.g. harry)
        yield call(cancelSyncTask, cid, syncTasks);
    }

    if (parentCid) {
        // cancel sync for coupon in case it was not synced and was cloned (private)
        yield call(cancelSyncTask, parentCid, syncTasks);
    }
}

function* couponChangeSaga(
    syncTasks: Map<string, Task>,
    action: CouponActions.CouponChangedCommonAction,
): SagaIterator<void> {
    log.debug("couponSyncSaga:couponChangeSaga", {action});
    let cid = null;
    // @ts-expect-error
    const context = action?.context;
    const payload = action?.payload;
    cid = context?.cid || payload?.cid;

    if (!cid) return;

    // if it is triggered, sync without delay
    const syncWithoutDelay = action.type === CouponActions.TRIGGER_COUPON_SYNC;

    const coupon = yield select(CouponSelectors.getCoupon, cid);

    log.debug("couponSyncSaga:couponChangeSaga", {action});

    if (!coupon || coupon.type === "bag" || coupon.type === "subscription") return;

    const couponChangedAction = CouponActions.couponChanged(cid, coupon);
    yield put(couponChangedAction);

    const currentPurchaseStepId = yield select(
        PurchaseSelectors.getCurrentPurchaseStepId,
    );

    if (currentPurchaseStepId === "CONFIRM") {
        return;
    }

    // @ts-expect-error
    yield* syncCouponChangeFlow(
        syncTasks,
        couponChangedAction,
        syncWithoutDelay,
        action.type, // original action type as origin for logging
    );
}

/**
 * received a coupon
 * save modified timestamp
 */
function receivedCoupon(action: any) {
    const {payload} = action;
    const {cid, coupon} = payload;
    if (!cid || !coupon) {
        return;
    }
    log.debug(`received coupon with cid ${cid}, update timestamp to ${coupon.modified}`);
    timestamps[cid] = coupon.modified;
}

/**
 * removed a coupon
 * remove associated modified timestamp unless we are awaiting the sync
 */
function* removedCoupon(action: any): SagaIterator<void> {
    const {payload} = action;
    const {cid} = payload;
    const isDelayed = yield select(CouponSelectors.isDelayed(cid));
    log.debug(`removed coupon with cid ${cid}. isDelayed value is ${isDelayed}`);
    if (isDelayed) {
        log.debug(`removed coupon with cid ${cid}. will remove the timestamp`);
        omit(timestamps, [cid]);
    }
}

/**
 * coupon was updated in another saga (e.g. sharedBetSaga)
 * update the modified timestamp
 */
function modifiedTimestampUpdated(action: any) {
    const {payload} = action;
    const {cid, modified}: {cid: string; modified: string} = payload;
    log.debug(`modified timestamp for cid ${cid}, update timestamp to ${modified}`);
    timestamps[cid] = modified;
}

/**
 * [HRS1-494] A/B test
 * Catch and track the status (error + status or no error) of the played bet
 * when we've pressed "Lägg spel" from the live view
 */
function* liveViewABTestTrackFlow({
    payload,
}: PurchaseActions.ConfirmBetAction): SagaIterator<void> {
    const {liveView} = payload;
    if (liveView) {
        const betAction = yield take([PLAYED_BET]);
        trackHorseEvent({
            event: "liveViewCountDownPlaceBetReponseEvent",
            error: Boolean(betAction.error),
            status: betAction.payload.status,
        });
    }
}

export default function* couponSyncSaga(
    syncTasks: Map<string, Task> = new Map(),
): SagaIterator<void> {
    yield takeEvery(
        [
            CouponActions.CLEAR_COUPON,
            CouponActions.TOGGLE_START,
            CouponActions.TOP7_REORDER_STARTS,
            CouponActions.TOGGLE_RESERVE,
            CouponActions.TOGGLE_ALL_STARTS,
            CouponActions.SELECT_RACE_BET_TYPE,
            CouponActions.SET_BOOST_SELECTED,
            CouponActions.SET_SYSTEMS,
            CouponActions.SET_ONLY_VX,
            CouponActions.CHANGE_STAKE,
            CouponActions.CHANGE_FLEX_BET_COST,
            CouponActions.CHANGE_HARRY_BET_LIMIT,
            CouponActions.SELECT_BET_METHOD,
            CouponActions.SELECT_RAKET_SYSTEM,
            CouponActions.TOGGLE_BANKER,
            CouponActions.UPDATE_TOP7_COUPON,
            CouponActions.SELECT_HARRY_OPEN,
            CouponActions.SET_HARRY_SUBSCRIPTION_SELECTED,
            CouponActions.SET_HARRY_BAG,
            CouponActions.SET_HARRY_SUBSCRIPTION_AMOUNT,
            CouponActions.SET_HARRY_SUBSCRIPTION_DELIVERY_OPTION,
            CouponActions.SET_COUPON_RACES,
            CouponActions.TRIGGER_COUPON_SYNC,
        ],
        couponChangeSaga,
        syncTasks,
    );
    yield takeLatest(PLAYED_BET, cancelCouponSyncFlow, syncTasks);
    // TODO: should actually refactor and do it the same way on PLAYED_BET too, but it needs dedicated testing, so wait till Jesper is back
    yield takeLatest(STARTED_VARENNE_FLOW, cancelCouponSync, syncTasks);
    yield takeLatest(PurchaseActions.CONFIRM_BET, couponPlayFlow, syncTasks);
    yield takeEvery(PurchaseActions.CONFIRM_BET, liveViewABTestTrackFlow); // TODO: remove?
    yield takeLatest(LOGIN_FINISHED, saveAllLocalCoupons);
    yield takeLatest(CouponActions.RECEIVE_COUPON, receivedCoupon);
    yield takeLatest(CouponActions.REMOVE_COUPON, removedCoupon);
    yield takeLatest(
        CouponActions.COUPON_MODIFIED_TIMESTAMP_UPDATED,
        modifiedTimestampUpdated,
    );
}
function getCouponUrl(variation: number, isReduced: boolean): string {
    if (variation === 1) {
        return `${COUPON_SERVICE_URL}`;
    }
    return isReduced ? COUPON_SERVICE_URL : `${USER_COUPON_SERVICE_URL}`;
}
