import root from "window-or-global";

export const INFO = "INFO";
export const ERROR = "ERROR";
export const SUBMIT_ERROR = "SUBMIT_ERROR";
export const WARNING = "WARNING";
export const SUBMIT_WARNING = "SUBMIT_WARNING";

const generateId = (type, level) => root.btoa(`${type}_${level}`);

/**
 * Create a "coupon error". This means the coupon can't be placed until the issue has been fixed.
 */
export function error(type, text, context) {
    if (!text) return null;

    return {id: generateId(type, "error"), type, level: ERROR, text, context};
}

/**
 * Create a "coupon submit error". This means the coupon can't be placed until the issue has been fixed.
 * The error will be shown when the user tries to place the coupon.
 */
export function submitError(type, text, context) {
    if (!text) return null;

    return {
        id: generateId(type, "submitError"),
        type,
        level: SUBMIT_ERROR,
        text,
        context,
    };
}

/**
 * Create a "coupon warning". The coupon can still be placed.
 */
export function warning(type, text, context) {
    if (!text) return null;

    return {id: generateId(type, "warning"), type, level: WARNING, text, context};
}

/**
 * Create a "coupon submit warning". The coupon can still be placed.
 * The warning will be shown when the user tries to place the coupon.
 */
export function submitWarning(type, text, context) {
    if (!text) return null;

    return {
        id: generateId(type, "submitWarning"),
        type,
        level: SUBMIT_WARNING,
        text,
        context,
    };
}

export const isSubmitError = (message) => message.level === SUBMIT_ERROR;

export const isError = (message) => message.level === ERROR;

export const isSubmitWarning = (message) => message.level === SUBMIT_WARNING;

export const isWarning = (message) => message.level === WARNING;
