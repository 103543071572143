import {union, filter, some, flow} from "lodash/fp";
import * as React from "react";
import type {StartData} from "@atg-horse-shared/bet-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getTop7SneakState} from "@atg-horse/horse-bet";
import {CheckAlt} from "atg-ui-components/icons";
// TODO: @atg-horse/bet-codereceipt -> atg-horse-bet -> @atg-horse/bet-codereceipt
import * as betCodeHorsesCheckMarksstyles from "./BetCodeHorsesCheckMarks.styles";
import * as styles from "./CodeResultMark.styles";

export type TableProps = {
    receipt: {
        [key: string]: any;
    };
    hideWinMark?: boolean;
    actions: {
        markAsSneaked: (...args: Array<any>) => any;
    };
};

export type ColumnData = {
    results?: Array<StartData>;
    bets: Array<StartData>;
    reserves: Array<StartData>;
    id: string;
    boxedBets?: Array<StartData>;
};

export const hasPickedWinner = (
    columnData: ColumnData,
    tableProps: TableProps,
): boolean => {
    if (tableProps && tableProps.hideWinMark) return false;
    return flow(
        union(columnData.reserves),
        filter((pick) => !pick.scratched),
        some((pick) => pick.win === true),
    )(columnData.bets);
};

export type Props = {
    columnData: ColumnData;
    tableProps: TableProps;
};

function CodeResultMark({columnData, tableProps}: Props) {
    if (!hasPickedWinner(columnData, tableProps)) return null;

    let css = betCodeHorsesCheckMarksstyles.correct;
    let placementStatus = "correct";
    if (columnData.boxedBets) {
        const sneakState = getTop7SneakState(tableProps.receipt);
        // @ts-expect-error
        const {tempWin, placementCorrect} = sneakState[columnData.id];
        if (tempWin) {
            placementStatus = "tempCorrect";
            css = betCodeHorsesCheckMarksstyles.tempCorrect;
        } else if (placementCorrect) {
            placementStatus = "placementCorrect";
            css = betCodeHorsesCheckMarksstyles.placementCorrect;
        }
    }

    return (
        <div data-test-id={placementStatus} css={css}>
            <CheckAlt style={styles.checkIcon} />
        </div>
    );
}

export default CodeResultMark;
