/**
 * This function should never be called.
 * @param shouldBeNever Variable used to determine that function is never called by only allowing type never.
 *
 * ```ts
 *  type MyUnion = "foo" | "bar";
 *
 *  const var: MyUnion = "foo";
 *
 *  if (var === "foo") return 1;
 *
 *  if (var === "bar") return 2;
 *
 *  return assertNever(var);
 * ```
 */
export function assertNever(shouldBeNever: never) {
    console.warn("assertNever was called with incorrect argument type, was not never.", {
        shouldBeNever,
    });
}
