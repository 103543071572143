import {isEmpty} from "lodash/fp";
import * as React from "react";
import type {StartData, HorseData} from "@atg-horse-shared/bet-types";
import type {Props} from "./CodeResultMark";
import CodeResultMark from "./CodeResultMark";
import * as styles from "./CodeHorsesColumn.styles";

type BankerProps = {
    banker: StartData;
};

const DEFAULT_HORSE: HorseData = {name: "", nationality: ""};
function Banker({banker: {number, horse = DEFAULT_HORSE}}: BankerProps) {
    return (
        <span>
            <span className="space-left" data-test-id="horse-number">
                {number}
            </span>
            <span className="space-left" data-test-id="horse-name">
                {horse.name}
            </span>
            <span>{horse.nationality}</span>
        </span>
    );
}

function CodeHorsesColumn({columnData, tableProps}: Props) {
    const winners = columnData.results || columnData.bets; // Results are not available for Raket
    if (isEmpty(winners)) return <div>Inget rätt</div>;

    const horses =
        winners.length === 1 ? (
            <Banker banker={winners[0]} />
        ) : (
            winners.map((winner, i) => (
                <span
                    key={i + winner.number}
                    className="space-right"
                    data-test-id="horse-number"
                >
                    {winner.number}
                </span>
            ))
        );

    return (
        <div css={styles.root}>
            <CodeResultMark columnData={columnData} tableProps={tableProps} />
            <span css={styles.horseInfo}>{horses}</span>
        </div>
    );
}

export default CodeHorsesColumn;
